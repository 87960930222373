import React from "react";
import { Box, Typography } from "@mui/material";

const USPSection = () => {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          margin: "100px 0",
          display: { desktop: "block", laptop: "block", mobile: "none" },
        }}
      >
        <Box
          sx={{
            width: {
              desktop: "calc(100% - 400px)",
              laptop: "calc(100% - 300px)",
              tablet: "calc(100% - 200px)",
            },
            height: { desktop: "1000px", laptop: "900px", tablet: "800px" },
            borderRadius: "70%",
            border: "2px dashed #2196f3",
            position: "relative",
            left: "-300px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: { laptop: "250px", tablet: "200px" },
              right: { desktop: "-540px", laptop: "-470px", tablet: "-360px" },
              zIndex: "1",
            }}
          >
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box
                style={{
                  width: "124px",
                  height: "124px",
                  borderRadius: "50%",
                  background: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                  }}
                  component="img"
                  src="/assets/gif/cost_effectiveness.gif"
                />
              </Box>
              <Box
                sx={{
                  maxWidth: {
                    desktop: "500px",
                    laptop: "400px",
                    tablet: "300px",
                  },
                  paddingTop: "20px",
                }}
              >
                <Typography variant="h4">
                  Cost Visibility and Control
                </Typography>
                <Typography variant="body1" style={{ marginTop: "20px" }}>
                  Experience crystal-clear transparency into your cloud spending
                  with powerful insights. Take the reins on your budget and make
                  savvy decisions that boost efficiency!
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "490px",
              right: { desktop: "-580px", laptop: "-460px", tablet: "-330px" },
              zIndex: "1",
            }}
          >
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box
                style={{
                  width: "124px",
                  height: "124px",
                  borderRadius: "50%",
                  background: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                  }}
                  component="img"
                  src="/assets/gif/risk.gif"
                />
              </Box>
              <Box
                sx={{
                  maxWidth: {
                    desktop: "500px",
                    laptop: "400px",
                    tablet: "300px",
                  },
                  paddingTop: "20px",
                }}
              >
                <Typography variant="h4">Anomaly Detection & Alerts</Typography>
                <Typography variant="body1" style={{ marginTop: "20px" }}>
                  Stay ahead of the game with real-time alerts for any
                  unexpected spending spikes—no disruptions, just proactive
                  management. Monitor your cloud usage in real time and tackle
                  anomalies head-on!
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              desktop: "calc(100% - 800px)",
              laptop: "calc(100% - 600px)",
              tablet: "calc(100% - 400px)",
            },
            height: { desktop: "1000px", laptop: "900px", tablet: "800px" },
            borderRadius: "50%",
            border: "2px dashed #2196f3",
            position: "absolute",
            top: "0",
            left: "-40%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "150px",
              right: { desktop: "-500px", laptop: "-430px", tablet: "-320px" },
              zIndex: "1",
            }}
          >
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box
                style={{
                  width: "124px",
                  height: "124px",
                  borderRadius: "50%",
                  background: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                  }}
                  component="img"
                  src="/assets/gif/budgeting.gif"
                />
              </Box>
              <Box
                sx={{
                  maxWidth: {
                    desktop: "500px",
                    laptop: "400px",
                    tablet: "300px",
                  },
                  paddingTop: "20px",
                }}
              >
                <Typography variant="h4">
                  Smart Saving Recommendations
                </Typography>
                <Typography variant="body1" style={{ marginTop: "20px" }}>
                  Unlock tailored savings strategies designed just for you—
                  without sacrificing performance. Maximize your cloud budget
                  effortlessly while keeping operations smooth!
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "380px",
              right: { desktop: "-570px", laptop: "-480px", tablet: "-380px" },
              zIndex: "1",
            }}
          >
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box
                style={{
                  width: "124px",
                  height: "124px",
                  borderRadius: "50%",
                  background: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                  }}
                  component="img"
                  src="/assets/gif/cloud.gif"
                />
              </Box>
              <Box
                sx={{
                  maxWidth: {
                    desktop: "500px",
                    laptop: "400px",
                    tablet: "300px",
                  },
                  paddingTop: "20px",
                }}
              >
                <Typography variant="h4">Multi-Cloud Support</Typography>
                <Typography variant="body1" style={{ marginTop: "20px" }}>
                  Effortlessly juggle resources across multiple cloud platforms
                  from one central hub. Enjoy seamless flexibility while
                  optimizing your entire cloud ecosystem without a hitch!
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "600px",
              right: { desktop: "-550px", laptop: "-430px", tablet: "-280px" },
              zIndex: "1",
            }}
          >
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box
                style={{
                  width: "124px",
                  height: "124px",
                  borderRadius: "50%",
                  background: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                  }}
                  component="img"
                  src="/assets/gif/save.gif"
                />
              </Box>
              <Box
                sx={{
                  maxWidth: {
                    desktop: "500px",
                    laptop: "400px",
                    tablet: "300px",
                  },
                  paddingTop: "20px",
                }}
              >
                <Typography variant="h4">Persona-Based Reports</Typography>
                <Typography variant="body1" style={{ marginTop: "20px" }}>
                  Dive into custom reports crafted for every role in your
                  organization. Empower your team with actionable insights that
                  fuel smart decisions while keeping performance at its peak!
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          margin: "100px 0",
          display: {
            desktop: "none",
            laptop: "none",
            mobile: "block",
          },
        }}
      >
        <Box
          sx={{
            width: { mobile: "400px", tablet: "1000px" },
            height: { mobile: "1400px", tablet: "1000px" },
            borderRadius: "50%",
            border: "2px dashed #2196f3",
            position: "relative",
            top: "0",
            left: { mobile: "-300px", tablet: "-730px" },
            // this needs to done to make UI responsive as we want
            // its not possible to handle with existing breakpoint since tablet and laptop have big distance in between
            "@media(min-width: 500px)": {
              left: "-250px",
            },
            "@media(min-width: 642px)": {
              left: "-200px",
            },
            "@media(min-width: 768px)": {
              left: "-780px",
            },
            "@media(min-width: 850px)": {
              left: "-700px",
            },
            "@media(min-width: 940px)": {
              left: "-670px",
            },
            "@media(min-width: 1028px)": {
              left: "-600px",
            },
            "@media(min-width: 1100px)": {
              left: "-560px",
            },
            "@media(min-width: 1200px)": {
              left: "-500px",
            },
            "@media(min-width: 1300px)": {
              left: "-450px",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: { mobile: "100px", tablet: "30px" },
              right: { mobile: "-245px", tablet: "-400px" },
              zIndex: "1",
            }}
          >
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box
                sx={{
                  width: { tablet: "100px", mobile: "70px" },
                  height: { tablet: "100px", mobile: "70px" },
                  borderRadius: "50%",
                  background: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                  }}
                  component="img"
                  src="/assets/gif/budgeting.gif"
                />
              </Box>
              <Box
                sx={{
                  maxWidth: {
                    mobile: "250px",
                    tablet: "500px",
                  },
                  paddingTop: "20px",
                }}
              >
                <Typography variant="h4">
                  Smart Saving Recommendations
                </Typography>
                <Typography variant="body1" style={{ marginTop: "10px" }}>
                  Unlock tailored savings strategies designed just for you—
                  without sacrificing performance. Maximize your cloud budget
                  effortlessly while keeping operations smooth!
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: { mobile: "330px", tablet: "200px" },
              right: { mobile: "-285px", tablet: "-520px" },
              zIndex: "1",
            }}
          >
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box
                sx={{
                  width: { tablet: "100px", mobile: "70px" },
                  height: { tablet: "100px", mobile: "70px" },
                  borderRadius: "50%",
                  background: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                  }}
                  component="img"
                  src="/assets/gif/cloud.gif"
                />
              </Box>
              <Box
                sx={{
                  maxWidth: {
                    mobile: "250px",
                    tablet: "500px",
                  },
                  paddingTop: "20px",
                }}
              >
                <Typography variant="h4">Multi-Cloud Support</Typography>
                <Typography variant="body1" style={{ marginTop: "20px" }}>
                  Effortlessly juggle resources across multiple cloud platforms
                  from one central hub. Enjoy seamless flexibility while
                  optimizing your entire cloud ecosystem without a hitch!
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: { mobile: "570px", tablet: "400px" },
              right: { mobile: "-250px", tablet: "-475px" },
              zIndex: "1",
            }}
          >
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box
                sx={{
                  width: { tablet: "100px", mobile: "70px" },
                  height: { tablet: "100px", mobile: "70px" },
                  borderRadius: "50%",
                  background: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                  }}
                  component="img"
                  src="/assets/gif/save.gif"
                />
              </Box>
              <Box
                sx={{
                  maxWidth: { mobile: "200px", tablet: "400px" },
                  paddingTop: "20px",
                }}
              >
                <Typography variant="h4">Persona-Based Reports</Typography>
                <Typography variant="body1" style={{ marginTop: "20px" }}>
                  Dive into custom reports crafted for every role in your
                  organization. Empower your team with actionable insights that
                  fuel smart decisions while keeping performance at its peak!
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: { mobile: "860px", tablet: "600px" },
              right: { mobile: "-240px", tablet: "-550px" },
              zIndex: "1",
            }}
          >
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box
                sx={{
                  width: { tablet: "100px", mobile: "70px" },
                  height: { tablet: "100px", mobile: "70px" },
                  borderRadius: "50%",
                  background: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                  }}
                  component="img"
                  src="/assets/gif/cost_effectiveness.gif"
                />
              </Box>
              <Box
                sx={{
                  maxWidth: {
                    mobile: "200px",
                    tablet: "500px",
                  },
                  paddingTop: "20px",
                }}
              >
                <Typography variant="h4">
                  Cost Visibility and Control
                </Typography>
                <Typography variant="body1" style={{ marginTop: "20px" }}>
                  Experience crystal-clear transparency into your cloud spending
                  with powerful insights. Take the reins on your budget and make
                  savvy decisions that boost efficiency!
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: { mobile: "1150px", tablet: "800px" },
              right: { mobile: "-250px", tablet: "-430px" },
              zIndex: "1",
            }}
          >
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box
                sx={{
                  width: { tablet: "100px", mobile: "70px" },
                  height: { tablet: "100px", mobile: "70px" },
                  borderRadius: "50%",
                  background: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                  }}
                  component="img"
                  src="/assets/gif/risk.gif"
                />
              </Box>
              <Box
                sx={{
                  maxWidth: {
                    mobile: "250px",
                    tablet: "500px",
                  },
                  paddingTop: "20px",
                }}
              >
                <Typography variant="h4">Anomaly Detection & Alerts</Typography>
                <Typography variant="body1" style={{ marginTop: "20px" }}>
                  Stay ahead of the game with real-time alerts for any
                  unexpected spending spikes—no disruptions, just proactive
                  management. Monitor your cloud usage in real time and tackle
                  anomalies head-on!
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default USPSection;
