// Package Imports
import React, { useState, useEffect, useMemo } from "react";
import { Box, Snackbar, Alert } from "@mui/material";
import { useHistory } from "react-router-dom";

// Local Imports
import {
  useGetCloudKeys,
  useGetCloudTypes,
  useGetCloudServices,
  useGetRecommendationTypes,
  useRecommendationsV2,
} from "../../hooks/useRecommendationsV2";
import {
  COST_SAVING_OPTIONS,
  RECOMMENDATION_TYPE_OPTIMAL_V2,
} from "../../utils/constants";
import DropDownArea from "./components/DropDownArea";
import RecommendationArea from "./components/RecommendationArea";

const RecommendationDetailsV2 = () => {
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const reactRouterDomHistory = useHistory();
  const [selectedVm, setSelectedVm] = useState("");
  const [selectedCloudProvider, setSelectedCloudProvider] = useState("");
  const [selectedCloudService, setSelectedCloudService] = useState("");
  const [selectedAccessKey, setSelectedAccessKey] = useState("");
  const [selectedCostSaving, setSelectedCostSaving] = useState(
    COST_SAVING_OPTIONS[0].value
  );
  const [selectedRecommendationType, setSelectedRecommendationType] =
    useState("");
  const [vmListToShow, setVmListToShow] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState({
    value: false,
    message: "",
  });

  const handleCloseSnackbar = () => {
    setOpenSnackbar({ value: false, message: "" });
  };

  const {
    data: cloudTypes,
    isLoading: areCloudTypesLoading,
    isError: isCloudTypesError,
    error: cloudTypesError,
  } = useGetCloudTypes();

  const { cloudProviderParam, serviceTypeParam } = useMemo(() => {
    return {
      cloudProviderParam: params.get("cloud_provider")?.toLowerCase(),
      serviceTypeParam: params.get("service_type")?.toLowerCase(),
    };
  }, [params]);

  const isValidProvider = useMemo(
    () =>
      cloudProviderParam
        ? cloudTypes?.some((ct) => ct.name.toLowerCase() === cloudProviderParam)
        : false,
    [cloudProviderParam, cloudTypes]
  );

  useEffect(() => {
    if (cloudTypes?.length > 0) {
      if (cloudProviderParam && !isValidProvider) {
        setOpenSnackbar({
          value: true,
          message: "Invalid cloud provider selected",
        });
      }

      setSelectedCloudProvider(
        cloudProviderParam ?? cloudTypes[0]?.name?.toLowerCase()
      );
    } else if (!areCloudTypesLoading) {
      setOpenSnackbar({ value: true, message: "No cloud types found" });
    }
  }, [cloudTypes, isValidProvider, cloudProviderParam, areCloudTypesLoading]);

  const {
    data: accessKeys,
    isLoading: areAccessKeyLoading,
    isError: isAccessKeyError,
    error: accessKeyError,
  } = useGetCloudKeys(
    selectedCloudProvider || cloudTypes?.[0]?.name?.toLowerCase()
  );

  useEffect(() => {
    if (accessKeys?.length > 0) {
      setSelectedAccessKey(accessKeys[0]?.id);
    }
  }, [accessKeys]);

  const {
    data: cloudServices,
    isLoading: areCloudServicesLoading,
    isError: isCloudServicesError,
    error: cloudServicesError,
  } = useGetCloudServices();

  useEffect(() => {
    if (cloudServices?.length > 0) {
      const isValidService = cloudServices.some(
        (cs) => cs.name.toLowerCase() === serviceTypeParam
      );

      if (serviceTypeParam && !isValidService) {
        setOpenSnackbar((prev) => {
          if (prev.message !== "Invalid service type selected") {
            return { value: true, message: "Invalid service type selected" };
          }
          return prev;
        });
      }

      setSelectedCloudService((prev) => {
        const newService =
          serviceTypeParam ?? cloudServices[0]?.name?.toLowerCase();
        if (prev !== newService) {
          return newService;
        }
        return prev;
      });
    } else if (!areCloudServicesLoading) {
      setOpenSnackbar((prev) => {
        if (prev.message !== "No cloud services found") {
          return { value: true, message: "No cloud services found" };
        }
        return prev;
      });
    }
  }, [serviceTypeParam, cloudServices, areCloudServicesLoading]);

  const {
    data: recommendationTypes,
    isLoading: areRecommendationTypesLoading,
    isError: isRecommendationTypesError,
    error: recommendationTypesError,
  } = useGetRecommendationTypes();

  useEffect(() => {
    if (recommendationTypes?.length > 0) {
      setSelectedRecommendationType(
        recommendationTypes[0]?.name?.toLowerCase()
      );
    }
    if (!areRecommendationTypesLoading && recommendationTypes?.length === 0) {
      setOpenSnackbar({
        value: true,
        message: "No recommendation types found",
      });
    }
  }, [accessKeys, areRecommendationTypesLoading, recommendationTypes]);

  const handleCloudChanged = (event) => {
    setSelectedAccessKey(null);
    setSelectedVm(null);
    setSelectedCloudProvider(event.target.value);
    reactRouterDomHistory.replace(
      `/recommendation-details?service_type=${
        params.get("service_type") ?? selectedCloudService
      }&cloud_provider=${event.target.value}`
    );
  };

  const handleAccessKeyChanged = (event) => {
    setSelectedVm(null);
    if (event.target.value && event.target.value !== "") {
      setSelectedAccessKey(event.target.value);
    }
  };

  const handleServiceChanged = (event) => {
    setSelectedCloudService(event.target.value);
    reactRouterDomHistory.replace(
      `/recommendation-details?service_type=${
        event.target.value
      }&cloud_provider=${params.get("cloud_provider") ?? selectedCloudProvider}`
    );
  };

  const handleRecommendationTypeChanged = (event) => {
    setSelectedRecommendationType(event.target.value);
  };

  const {
    data: recommendationData,
    error: recommendationDataError,
    isLoading: isRecommendationDataPending,
  } = useRecommendationsV2({
    cloudProvider: selectedCloudProvider,
    accessKeyId: selectedAccessKey,
    service: selectedCloudService,
    recommendationType: selectedRecommendationType,
    costSaving: selectedCostSaving,
  });

  const showDropDownErrorMessage = useMemo(() => {
    if (
      isCloudTypesError ||
      isAccessKeyError ||
      isCloudServicesError ||
      isRecommendationTypesError
    ) {
      return (
        cloudTypesError?.message ||
        accessKeyError?.message ||
        cloudServicesError?.message ||
        recommendationTypesError?.message
      );
    }
  }, [
    isCloudTypesError,
    isAccessKeyError,
    isCloudServicesError,
    isRecommendationTypesError,
    cloudTypesError?.message,
    accessKeyError?.message,
    cloudServicesError?.message,
    recommendationTypesError?.message,
  ]);

  const handleSelectVm = (vm) => {
    setSelectedVm(vm);
  };

  useEffect(() => {
    if (recommendationData?.vm_list && recommendationData?.vm_list.length > 0) {
      setVmListToShow(recommendationData?.vm_list ?? []);
    } else {
      setVmListToShow([]);
    }
  }, [recommendationData]);

  useEffect(() => {
    setSelectedVm(vmListToShow?.[0]);
  }, [vmListToShow]);

  const handleCostSavingChange = (event) => {
    setSelectedCostSaving(event.target.value);
  };

  const areAllVmsOptimal = useMemo(() => {
    if (!vmListToShow || vmListToShow.length === 0) {
      return false;
    }
    return vmListToShow.every(
      (vm) => vm.recommended_action === RECOMMENDATION_TYPE_OPTIMAL_V2
    );
  }, [vmListToShow]);

  return (
    <Box>
      <Box display="grid" gap="20px" gridTemplateColumns="repeat(6, 1fr)">
        <DropDownArea
          areDropDownOptionsLoading={
            areCloudTypesLoading ||
            areAccessKeyLoading ||
            areCloudServicesLoading ||
            areRecommendationTypesLoading
          }
          selectedCloudProvider={selectedCloudProvider}
          selectedAccessKey={selectedAccessKey}
          selectedCloudService={selectedCloudService}
          selectedRecommendationType={selectedRecommendationType}
          selectedCostSaving={selectedCostSaving}
          cloudTypes={cloudTypes}
          accessKeys={accessKeys}
          cloudServices={cloudServices}
          recommendationTypes={recommendationTypes}
          handleCloudChanged={handleCloudChanged}
          handleAccessKeyChanged={handleAccessKeyChanged}
          handleServiceChanged={handleServiceChanged}
          handleRecommendationTypeChanged={handleRecommendationTypeChanged}
          handleCostSavingChange={handleCostSavingChange}
          isRecommendationDataPending={isRecommendationDataPending}
          showDropDownErrorMessage={showDropDownErrorMessage}
          recommendationData={recommendationData}
        />
        <RecommendationArea
          isLoading={
            areCloudTypesLoading ||
            areAccessKeyLoading ||
            areCloudServicesLoading ||
            areRecommendationTypesLoading ||
            isRecommendationDataPending
          }
          vmListToShow={vmListToShow}
          selectedCloudService={selectedCloudService}
          recommendationDataError={recommendationDataError}
          selectedVm={selectedVm}
          selectedCloudProvider={selectedCloudProvider}
          selectedAccessKey={selectedAccessKey}
          handleSelectVm={handleSelectVm}
          recommendationData={recommendationData}
          accessKeys={accessKeys}
          areAllVmsOptimal={areAllVmsOptimal}
        />
      </Box>
      <Snackbar
        open={openSnackbar.value}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={openSnackbar.message ?? "Something went wrong"}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" variant="filled">
          {openSnackbar.message ?? "Something went wrong"}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RecommendationDetailsV2;
