import React, { useState, useMemo } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Chip } from "../../../ui/Chip";
import {
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_OCI,
  RECOMMENDATION_TAGS,
  RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE_V2,
  RECOMMENDATION_TYPE_IDLE_V2,
  RECOMMENDATION_TYPE_OPTIMAL_V2,
  RECOMMENDATION_DATA_KEYS,
  AVILABLE_SERVICES,
} from "../../../utils/constants";
import { MonitoringGraph } from "./MonitoringGraph";
import { MonitoringMemoryGraph } from "./MonitoringMemoryGraph";

import ParallelArchitecture from "../../../assets/png/parallel.png";
import CrossArchitecture from "../../../assets/png/cross_cloud.svg";
import SameArchitecture from "../../../assets/png/same_architecture.png";
import OptimalRecommenationEmptyState from "../../../assets/svg/edge-cases/recommendations-error-state.svg";
import AllVmsOptimalState from "../../../assets/svg/edge-cases/all-vms-optimal-state.svg";

const columns = [
  {
    field: "tag",
    headerName: "Recommendation",
    minWidth: 200,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    renderCell: (value) => {
      const architectureType = value.value;

      let logoUrl = null;
      if (architectureType === "parallel_architecture") {
        logoUrl = ParallelArchitecture;
      } else if (architectureType === "cross_architecture") {
        logoUrl = CrossArchitecture;
      } else if (architectureType === "same_architecture") {
        logoUrl = SameArchitecture;
      }
      return (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            height: "100%",
          }}
        >
          <Box
            component="img"
            src={logoUrl}
            alt="Architecture logo"
            width="15px"
            height="15px"
            style={{
              rotate: architectureType !== "cross_architecture" ? "90deg" : "",
            }}
          />
          <Typography variant="body2">
            {RECOMMENDATION_TAGS[value.value]}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "machine_type",
    headerName: "Machine Type",
    minWidth: 140,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
  },
  {
    field: "vcpu",
    headerName: "vCPUs",
    minWidth: 100,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
  },
  {
    field: "memory",
    headerName: "Memory (GiB)",
    minWidth: 120,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
  },
  {
    field: "new_machine_monthly_price",
    headerName: "Price per Month",
    minWidth: 130,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    sortComparator: (v1, v2) => {
      return v1 - v2;
    },
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          ${value.row.new_machine_monthly_price}/m
        </Box>
      );
    },
  },
  {
    field: "usage_cpu",
    headerName: "CPU Usage",
    minWidth: 120,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    sortable: false,
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <i class="fi fi-sr-stats" />
        </Box>
      );
    },
  },
  {
    field: "usage_memory",
    headerName: "Memory Usage",
    minWidth: 120,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    sortable: false,
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <i class="fi fi-sr-stats" />
        </Box>
      );
    },
  },
  {
    field: "cost_saving",
    headerName: "Saving Opportunity ($)",
    minWidth: 250,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    sortComparator: (v1, v2) => {
      return v1 - v2;
    },
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          Save ${value.row.cost_saving}/m
          <Chip
            sx={{
              backgroundColor: "transparent",
              color: "#358A01",
              border: "1px solid grey",
              borderRadius: "8px",
              padding: "0px",
              margin: "0px",
              fontWeight: 600,
              minWidth: "70px",
            }}
            label={`SAVE ${value.row.percentage_saved}%`}
          />
        </Box>
      );
    },
  },
  {
    field: "architecture",
    headerName: "Architecture",
    minWidth: 130,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
  },
];

const columnsForDatabases = [
  {
    field: "tag",
    headerName: "Recommendation",
    minWidth: 200,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    renderCell: (value) => {
      const architectureType = value.value;

      let logoUrl = null;
      if (architectureType === "parallel_architecture") {
        logoUrl = ParallelArchitecture;
      } else if (architectureType === "cross_architecture") {
        logoUrl = CrossArchitecture;
      } else if (architectureType === "same_architecture") {
        logoUrl = SameArchitecture;
      }
      return (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            height: "100%",
          }}
        >
          <Box
            component="img"
            src={logoUrl}
            alt="Architecture logo"
            width="15px"
            height="15px"
            style={{
              rotate: architectureType !== "cross_architecture" ? "90deg" : "",
            }}
          />
          <Typography variant="body2">
            {RECOMMENDATION_TAGS[value.value]}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "dbinstance_class",
    headerName: "Machine Type",
    minWidth: 140,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
  },
  {
    field: "number_of_threads",
    headerName: "vCPUs",
    minWidth: 100,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
  },
  {
    field: "memory",
    headerName: "Memory (GiB)",
    minWidth: 120,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
  },
  {
    field: "storage_type",
    headerName: "Storage Type",
    minWidth: 120,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
  },
  {
    field: "machine_price",
    headerName: "Price per Month",
    minWidth: 130,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    sortComparator: (v1, v2) => {
      return v1.num - v2.num;
    },
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {value.row.machine_price?.str}
        </Box>
      );
    },
  },
  {
    field: "usage_cpu",
    headerName: "CPU Usage",
    minWidth: 120,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    sortable: false,
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <i class="fi fi-sr-stats" />
        </Box>
      );
    },
  },
  {
    field: "usage_memory",
    headerName: "Memory Usage",
    minWidth: 120,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    sortable: false,
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <i class="fi fi-sr-stats" />
        </Box>
      );
    },
  },
  {
    field: "saving",
    headerName: "Saving Opportunity ($)",
    minWidth: 250,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    sortComparator: (v1, v2) => {
      return v1.num - v2.num;
    },
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          Save {value.row.saving?.str}
          <Chip
            sx={{
              backgroundColor: "transparent",
              color: "#358A01",
              border: "1px solid grey",
              borderRadius: "8px",
              padding: "0px",
              margin: "0px",
              fontWeight: 600,
              minWidth: "70px",
            }}
            label={`SAVE ${value.row.percentage_saved}%`}
          />
        </Box>
      );
    },
  },
  {
    field: "architecture",
    headerName: "Architecture",
    minWidth: 130,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
  },
];

const getColorForAlternateRecommendedAction = (action) => {
  if (action === RECOMMENDATION_TYPE_OPTIMAL_V2) {
    return "green";
  } else if (action === RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE_V2) {
    return "#FFCC00";
  } else if (action === RECOMMENDATION_TYPE_IDLE_V2) {
    return "red";
  } else {
    return "#FFCC00";
  }
};

const EmptyStateView = ({ recommendedAction, selectedCloudService }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 4,
        textAlign: "center",
        width: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 501px)",
        margin: "auto",
        boxShadow: "none",
      }}
    >
      {recommendedAction === RECOMMENDATION_TYPE_OPTIMAL_V2 ? (
        <Box
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          <Box
            component="img"
            src={OptimalRecommenationEmptyState}
            style={{
              width: "30%",
            }}
          />
          <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
            {selectedCloudService === AVILABLE_SERVICES.INSTANCES
              ? "This instance is optimal. Try selecting an alternative VM."
              : "This database is optimal. Try selecting an alternative Database."}
          </Typography>
        </Box>
      ) : (
        <Box
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          <Box component="img" src={OptimalRecommenationEmptyState} />
          <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
            Woops! There's nothing to show right now. Try selecting an
            alternative VM.
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "end",
        backgroundColor: "blue",
      }}
    >
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const RecommendationsSection = ({
                                  selectedVm,
                                  cloudProvider,
                                  accessToken,
                                  areAllVmsOptimal,
                                  selectedCloudService,
                                }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [memoryDialogOpen, setMemoryDialogOpen] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);

  const {
    rows,
    maxSavingsPossibleFromRecommendation,
    missingRecommendationsByTag,
  } = useMemo(() => {
    const recommendations =
      selectedVm?.recommendations.map((recommendation, index) => ({
        id: index + 1,
        ...recommendation,
      })) ?? [];

    const groupByTag = Object.groupBy(recommendations, ({ tag }) => tag);

    const sortByDescWrtSavingPercent = Object.entries(groupByTag).flatMap(
      ([tag, recommendations]) => {
        const descBySavingPercent = recommendations.sort((a, b) => {
          return b.percentage_saved - a.percentage_saved;
        });
        return descBySavingPercent[0];
      },
    );

    const availableRecommendationsTags = sortByDescWrtSavingPercent.map(
      (re) => re.tag,
    );

    const missingRecommendationsByTag =
      selectedVm?.recommended_action !== RECOMMENDATION_TYPE_OPTIMAL_V2
        ? Object.keys(RECOMMENDATION_TAGS).filter((tag) => {
          return !availableRecommendationsTags.includes(tag);
        })
        : [];

    const listOfAllRecommendationCostSaving = sortByDescWrtSavingPercent.map(
      (recom) => recom.cost_saving,
    );
    const maxSavingsPossibleFromRecommendation = Math.max(
      ...listOfAllRecommendationCostSaving,
    );

    return {
      rows: sortByDescWrtSavingPercent,
      maxSavingsPossibleFromRecommendation,
      missingRecommendationsByTag,
    };
  }, [selectedVm]);

  const getColumns = (cloudProvider) => {
    if (selectedCloudService === AVILABLE_SERVICES.DATABASES) {
      return columnsForDatabases;
    }
    if (cloudProvider === CLOUD_PROVIDER_AWS) {
      return columns;
    } else if (cloudProvider === CLOUD_PROVIDER_OCI) {
      const tempCols = [
        ...columns.slice(0, 2),
        ...[
          {
            field: "ocpu",
            headerName: "oCPUs",
            width: 80,
            filterable: true,
            headerClassName: "header-row",
          },
        ],
        ...columns.slice(2, columns.length),
      ];
      return tempCols;
    } else {
      return columns;
    }
  };

  return (
    <Box gridColumn="span 4">
      <Card style={{ border: "2px solid rgba(33, 150, 243, 0.3)" }}>
        <CardContent>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <Box
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <Tooltip
                  title={
                    selectedVm[
                      RECOMMENDATION_DATA_KEYS.instanceName[
                        selectedCloudService
                        ]
                      ]
                  }
                >
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    style={{
                      maxWidth: "20ch",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {
                      selectedVm[
                        RECOMMENDATION_DATA_KEYS.instanceName[
                          selectedCloudService
                          ]
                        ]
                    }
                  </Typography>
                </Tooltip>
                <Typography variant="subtitle2">
                  {
                    selectedVm[
                      RECOMMENDATION_DATA_KEYS.machineType[selectedCloudService]
                      ]
                  }
                </Typography>
              </Box>
              <Box style={{ display: "flex", gap: "20px" }}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1">Memory</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      {
                        selectedVm[
                          RECOMMENDATION_DATA_KEYS.memory[selectedCloudService]
                          ]
                      }
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1">vCPU</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      {
                        selectedVm[
                          RECOMMENDATION_DATA_KEYS.vcpu[selectedCloudService]
                          ]
                      }
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1">Architecture</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      {
                        selectedVm[
                          RECOMMENDATION_DATA_KEYS.architecture[
                            selectedCloudService
                            ]
                          ]
                      }
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" textAlign="center">
                      Avg. CPU Utilization
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      {
                        selectedVm[
                          RECOMMENDATION_DATA_KEYS.avgOfAvgCPUUtilization[
                            selectedCloudService
                            ]
                          ]
                      }
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" textAlign="center">
                      Max CPU Utilization
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      {
                        selectedVm[
                          RECOMMENDATION_DATA_KEYS.avgOfMaxCPUUtilization[
                            selectedCloudService
                            ]
                          ]
                      }
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" textAlign="center">
                      Physical Processor
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" textAlign="center">
                      {
                        selectedVm[
                          RECOMMENDATION_DATA_KEYS.physicalProcessor[
                            selectedCloudService
                            ]
                          ]
                      }
                    </Typography>
                  </Box>
                </Box>

              </Box>
              {selectedCloudService === AVILABLE_SERVICES.DATABASES ? (
                <Box style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" textAlign="center">
                        Engine
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" textAlign="center">
                        {
                          selectedVm.engine
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" textAlign="center">
                        Storage Type
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" textAlign="center">
                        {
                          selectedVm.storage_type
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" textAlign="center">
                        Allocated Storage
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" textAlign="center">
                        {
                          selectedVm.allocated_storage
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" textAlign="center">
                        Storage Used
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" textAlign="center">
                        {
                          selectedVm.storage_used
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" textAlign="center">
                        Deployment Option
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" textAlign="center">
                        {
                          selectedVm.deployment_option
                        }
                      </Typography>
                    </Box>
                  </Box>
                </Box>) : null}
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Box>
                <Typography variant="h5" fontWeight="bold">
                  {selectedCloudService === AVILABLE_SERVICES.INSTANCES
                    ? selectedVm.cost_per_month?.str
                    : selectedVm.total_price?.str ||
                    `$${
                      selectedVm.current_instance_price?.num +
                      selectedVm.current_iops_price?.num +
                      selectedVm.current_storage_price?.num
                    }/m`}
                </Typography>
                <Typography variant="caption">Current Price</Typography>
              </Box>
              {selectedVm[
                RECOMMENDATION_DATA_KEYS.alternateRecommendedAction[
                  selectedCloudService
                  ]
                ] ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "flex-end",
                  }}
                >
                  <Box textAlign="right">
                    <Typography variant="subtitle1">
                      Alternate Recommendation Type
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Typography variant="subtitle2">
                      {
                        selectedVm[
                          RECOMMENDATION_DATA_KEYS.alternateRecommendedAction[
                            selectedCloudService
                            ]
                          ]
                      }
                    </Typography>
                    <Box
                      style={{
                        width: "5px",
                        height: "5px",
                        borderRadius: "50%",
                        backgroundColor: getColorForAlternateRecommendedAction(
                          selectedVm[
                            RECOMMENDATION_DATA_KEYS.alternateRecommendedAction[
                              selectedCloudService
                              ]
                            ],
                        ),
                      }}
                    />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </CardContent>
      </Card>
      {selectedCloudService === AVILABLE_SERVICES.DATABASES ? (
        <Card
          sx={{
            marginTop: "20px",
          }}
        >
          <CardContent>
            <Typography variant="h5" fontWeight="bold">
              Estimated monthly costs
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  borderRight: "1px solid #ECECEC",
                  pr: "20px",
                }}
              >
                <Typography variant="body2">Total cost</Typography>
                <Typography fontWeight="bold">
                  {selectedVm.total_price?.str}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderRight: "1px solid #ECECEC",
                  pr: "20px",
                }}
              >
                <Typography variant="body2">DB instance</Typography>
                <Typography fontWeight="bold">
                  {selectedVm.current_instance_price?.str}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderRight: "1px solid #ECECEC",
                  pr: "20px",
                }}
              >
                <Typography variant="body2">Storage</Typography>
                <Typography fontWeight="bold">
                  {selectedVm.current_storage_price?.str}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">Provisioned IOPS</Typography>
                <Typography fontWeight="bold">
                  {selectedVm.current_iops_price?.str}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ) : null}
      <Card
        sx={{
          marginTop: "20px",
          height:
            selectedCloudService === AVILABLE_SERVICES.INSTANCES
              ? "calc(100% - 240px)"
              : "calc(100% - 355px)",
        }}
      >
        <CardContent style={{ height: "100%" }}>
          {rows.length === 0 ? (
            <EmptyStateView
              recommendedAction={
                selectedVm[
                  RECOMMENDATION_DATA_KEYS.recommendedAction[
                    selectedCloudService
                    ]
                  ]
              }
              selectedCloudService={selectedCloudService}
            />
          ) : (
            <>
              {areAllVmsOptimal ? (
                <Box
                  style={{
                    gridColumn: "span 4",
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                  }}
                >
                  <Box
                    style={{
                      placeItems: "center",
                    }}
                  >
                    <Box component="img" src={AllVmsOptimalState} />
                    <Typography
                      variant="body1"
                      sx={{ mt: 1 }}
                      fontWeight="bold"
                    >
                      Woohoo! Your current key is perfect. Consider selecting
                      another key.
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box
                    style={{
                      display: "flex",
                      gap: "20px",
                      justifyContent: "flex-end",

                      "& .Mui-disabled": {
                        backgroundColor: "red",
                      },
                    }}
                  >
                    <FormControl disabled fullWidth>
                      <InputLabel id="select-ocpus-label">oCPUs</InputLabel>
                      <Select
                        labelId="select-ocpus-label"
                        id="select-ocpus"
                        // value={selectedCloudProvider}
                        label="oCPUs"
                        // onChange={onCloudChanged}
                        // sx={FILTER_SELECT_STYLE}
                        disabled
                      >
                        {/* {CLOUD_PROVIDERS.map((cloudProvider) => (
                      <MenuItem key={cloudProvider} value={cloudProvider}>
                        {getCloudProviderName(cloudProvider)}
                      </MenuItem>
                    ))} */}
                      </Select>
                    </FormControl>
                    <FormControl disabled fullWidth>
                      <InputLabel id="select-memory-label">
                        Memory in GB
                      </InputLabel>
                      <Select
                        labelId="select-memory-label"
                        id="select-memory"
                        // value={selectedCloudProvider}
                        label="Memory in GB"
                        // onChange={onCloudChanged}
                        // sx={FILTER_SELECT_STYLE}
                        disabled
                      >
                        {/* {CLOUD_PROVIDERS.map((cloudProvider) => (
                      <MenuItem key={cloudProvider} value={cloudProvider}>
                        {getCloudProviderName(cloudProvider)}
                      </MenuItem>
                    ))} */}
                      </Select>
                    </FormControl>
                    <FormControl disabled fullWidth>
                      <InputLabel id="select-usage-type-label">
                        Usage Type
                      </InputLabel>
                      <Select
                        labelId="select-usage-type-label"
                        id="select-usage-type"
                        // value={selectedCloudProvider}
                        label="Usage Type"
                        // onChange={onCloudChanged}
                        // sx={FILTER_SELECT_STYLE}
                        disabled
                      >
                        {/* {CLOUD_PROVIDERS.map((cloudProvider) => (
                      <MenuItem key={cloudProvider} value={cloudProvider}>
                        {getCloudProviderName(cloudProvider)}
                      </MenuItem>
                    ))} */}
                      </Select>
                    </FormControl>
                    <FormControl disabled fullWidth>
                      <InputLabel id="select-instance-storage-label">
                        Instance Storage
                      </InputLabel>
                      <Select
                        labelId="select-instance-storage-label"
                        id="select-instance-storage"
                        // value={selectedCloudProvider}
                        label="Instance Storage"
                        // onChange={onCloudChanged}
                        // sx={FILTER_SELECT_STYLE}
                        disabled
                      >
                        {/* {CLOUD_PROVIDERS.map((cloudProvider) => (
                      <MenuItem key={cloudProvider} value={cloudProvider}>
                        {getCloudProviderName(cloudProvider)}
                      </MenuItem>
                    ))} */}
                      </Select>
                    </FormControl>
                    <FormControl disabled fullWidth>
                      <InputLabel id="select-networking-label">
                        Networking
                      </InputLabel>
                      <Select
                        labelId="select-networking-label"
                        id="select-networking"
                        // value={selectedCloudProvider}
                        label="Networking"
                        // onChange={onCloudChanged}
                        // sx={FILTER_SELECT_STYLE}
                        disabled
                      >
                        {/* {CLOUD_PROVIDERS.map((cloudProvider) => (
                      <MenuItem key={cloudProvider} value={cloudProvider}>
                        {getCloudProviderName(cloudProvider)}
                      </MenuItem>
                    ))} */}
                      </Select>
                    </FormControl>
                    <Box textAlign="center">
                      <Typography variant="h5" fontWeight="bold" color="green">
                        ${maxSavingsPossibleFromRecommendation}/m
                      </Typography>
                      <Typography
                        variant="caption"
                        color="green"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        Estimated Savings
                      </Typography>
                    </Box>
                  </Box>
                  {selectedVm[
                    RECOMMENDATION_DATA_KEYS.subRecommendationAction[
                      selectedCloudService
                      ]
                    ] ? (
                    <>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          color="primary"
                          sx={{
                            borderBottom: "2px solid #2196F3",
                            width: "fit-content",
                            padding: "15px 20px 10px 20px",
                            textTransform: "uppercase",
                          }}
                        >
                          {
                            selectedVm[
                              RECOMMENDATION_DATA_KEYS.subRecommendationAction[
                                selectedCloudService
                                ]
                              ]
                          }
                        </Typography>
                      </Box>

                      <Box
                        style={{
                          margin: "20px 0",
                          border: "1px solid rgba(33, 150, 243, 0.5)",
                          padding: "10px",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography variant="body2">
                          {selectedVm[
                            RECOMMENDATION_DATA_KEYS.subRecommendationAction[
                              selectedCloudService
                              ]
                            ] === "Shutdown"
                            ? `Average Utilization of ${
                              selectedVm[
                                RECOMMENDATION_DATA_KEYS.instanceName[
                                  selectedCloudService
                                  ]
                                ]
                            } is less than 1%  in the last 7 days. So you could save by downsizing the instance.`
                            : `Average Utilization of ${
                              selectedVm[
                                RECOMMENDATION_DATA_KEYS.instanceName[
                                  selectedCloudService
                                  ]
                                ]
                            } is less than 1%  in the last 15 days. So you could save by downsizing the instance. `}
                        </Typography>
                      </Box>
                    </>
                  ) : null}
                  <Box
                    sx={{
                      "& .header-row": {
                        backgroundColor: "rgba(33, 150, 243, 0.1)",
                      },
                      margin: "20px 0",
                    }}
                  >
                    <DataGrid
                      rows={rows}
                      columns={getColumns(cloudProvider, selectedCloudService)}
                      initialState={{
                        filter: {
                          filterModel: {
                            items: columns.map((col) => ({
                              columnField: col.field,
                              operatorValue: "contains",
                              value: "",
                            })),
                          },
                        },
                        sorting: {
                          sortModel: [{ field: "tag", sort: "desc" }],
                        },
                      }}
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      onCellClick={(params, event) => {
                        event.defaultMuiPrevented = true;
                        setSelectedRecommendation(params.row);
                        if (params.field === "usage_cpu") {
                          setDialogOpen(true);
                        } else if (params.field === "usage_memory") {
                          setMemoryDialogOpen(true);
                        }
                      }}
                      autoHeight
                      hideFooter
                      density="comfortable"
                    />
                  </Box>
                  {missingRecommendationsByTag.length > 0 ? (
                    <Box
                      style={{
                        border: "1px solid rgba(33, 150, 243, 0.5)",
                        padding: "10px",
                        borderRadius: "4px",
                      }}
                    >
                      Please note: No{" "}
                      <b>
                        {missingRecommendationsByTag
                          .map((tag) => RECOMMENDATION_TAGS[tag])
                          .join(", ")}
                      </b>{" "}
                      Recommendation available.
                    </Box>
                  ) : null}
                </>
              )}
            </>
          )}
        </CardContent>
      </Card>
      <MonitoringGraph
        open={dialogOpen}
        vm={selectedVm}
        cloudProvider={cloudProvider}
        accessToken={accessToken}
        recommendation={selectedRecommendation}
        title={"CPU Usage Monitoring"}
        onClose={() => {
          setDialogOpen(false);
        }}
        handleClose={() => {
          setDialogOpen(false);
        }}
      />
      <MonitoringMemoryGraph
        open={memoryDialogOpen}
        vm={selectedVm}
        cloudProvider={cloudProvider}
        accessToken={accessToken}
        recommendation={selectedRecommendation}
        title={"Memory Monitoring"}
        onClose={() => {
          setMemoryDialogOpen(false);
        }}
        handleClose={() => {
          setMemoryDialogOpen(false);
        }}
      />
    </Box>
  );
};

export default RecommendationsSection;
