import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  List,
  Divider,
  CssBaseline,
  Tooltip,
  Menu,
  Avatar,
  Modal,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Fade,
  Button,
  Snackbar,
  Slide,
  Alert,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import history from "../../../utils/history";
import {
  ROUTE_ADD_NEW_CLOUD_PROVIDER,
  ROUTE_DASHBOARD,
  ROUTE_PROFILE,
  ROUTE_RECOMMENDATION_DETAILS,
  ROUTE_CROSS_CLOUD_RECOMMENDATIONS,
} from "../../../routers/routes";
import { useDispatch } from "react-redux";
import { logout } from "../../../services/authService";
import ConfirmationDialog from "../../../ui/ConfirmationDialog";
import { useState, useMemo, useEffect } from "react";
import CloudPulseLogo from "../../../assets/cloud_pulse_logo.svg";
import { useQueryClient } from "@tanstack/react-query";
import MenuItem from "@mui/material/MenuItem";
import { getInfo, isAuthenticated } from "../../../utils/jwtUtil";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../InputField/InputField";
import useProfile from "../../../hooks/useProfile";

const DRAWER_LABEL_DASHBOARD = "Dashboard";
const DRAWER_LABEL_RECOMMENDATIONS = "Recommendations";
const DRAWER_LABEL_ADD_TOKEN = "Add Token";

const FEEDBACK_QUESTIONS = [
  {
    id: 1,
    question:
      "I’m having trouble integrating CloudNudge with my cloud provider. What should I do?",
  },
  {
    id: 2,
    question:
      "I’m having trouble setting up my cloud accounts. What should I do?",
  },
  {
    id: 3,
    question:
      "What permissions does CloudNudge require to analyze my cloud usage?",
  },
  {
    id: 4,
    question: "Can I integrate multiple cloud accounts with CloudNudge?",
  },
  {
    id: 5,
    question:
      "I’m not seeing any data after setting up my cloud accounts. What could be wrong?",
  },
];
const DRAWER_LABEL_CROSS_CLOUD_RECOMMENDATIONS = "Cross Cloud";
const ICON_STYLE = {
  textAlign: "center",
  verticalAlign: "center",
  fontSize: "24px",
  color: "#3A3A3A",
  width: "24px",
  height: "24px",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  backgroundColor: "white",
  padding: 20,
  boxShadow: 24,
  p: 4,
};

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const authenticated = isAuthenticated();
  const userInfo = getInfo();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValuesForFormuseMemo = useMemo(() => {
    let defaultValues = {
      other_issues: "",
      suggestions: "",
    };

    FEEDBACK_QUESTIONS.forEach((question) => {
      defaultValues[`response_${question.id}`] = false;
    });

    return defaultValues;
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    control,
    reset,
    setError,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: defaultValuesForFormuseMemo,
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => {
    reset();
    setOpenModal(false);
  };

  const { submitFeedbackMutation } = useProfile();

  const submitFeedback = async (feedback) => {
    if (
      feedback.other_issues.trim().length === 0 &&
      feedback.suggestions.trim().length === 0
    ) {
      setError("root", {
        type: "manual",
        message: "You must fill out either suggestions or issue",
      });
      return;
    }
    submitFeedbackMutation.mutate(feedback);
    reset();
  };

  const otherIssues = watch("other_issues");
  const suggestions = watch("suggestions");
  useEffect(() => {
    if (otherIssues.trim().length > 0 || suggestions.trim().length > 0) {
      clearErrors();
    }
  }, [otherIssues, suggestions, clearErrors]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* TODO: Refactor this with our own AppBar class */}
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: "white",
        }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            onClick={() => {
              history.push(ROUTE_DASHBOARD);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "auto",
              height: "48px",
              cursor: "pointer",
            }}
            component="img"
            src={CloudPulseLogo}
          />
          {authenticated && (
            <div
              style={{
                marginLeft: "auto",
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ color: "black" }}
                >
                  {userInfo.first_name}
                </Typography>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="black"
                >
                  {/* <AccountCircle /> */}
                  <Avatar sx={{ width: 32, height: 32, fontSize: "14px" }}>
                    {userInfo?.first_name?.split("")[0]}
                    {userInfo?.last_name?.split("")[0]}
                  </Avatar>
                </IconButton>
              </Box>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    history.push(ROUTE_PROFILE);
                    handleClose();
                  }}
                  style={{ minHeight: "40px" }}
                >
                  <Avatar sx={{ width: 20, height: 20 }} /> &nbsp; Profile &
                  Plan
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleModalOpen();
                    handleClose();
                  }}
                  style={{ minHeight: "40px" }}
                >
                  <SearchIcon
                    style={{ color: "gray", width: 20, height: 20 }}
                  />{" "}
                  &nbsp; Help & Issue Board
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setDialogOpen(true);
                  }}
                  style={{ minHeight: "40px" }}
                >
                  <>
                    <i
                      class="fi fi-rr-sign-out-alt"
                      style={{ color: "gray", width: 20, height: 20 }}
                    />
                  </>
                  &nbsp; Logout
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            DRAWER_LABEL_ADD_TOKEN,
            DRAWER_LABEL_DASHBOARD,
            DRAWER_LABEL_RECOMMENDATIONS,
            DRAWER_LABEL_CROSS_CLOUD_RECOMMENDATIONS,
          ].map((text) => (
            <Tooltip
              key={text}
              title={text}
              disableHoverListener={open ? true : false}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => {
                    if (text === DRAWER_LABEL_DASHBOARD) {
                      history.push(ROUTE_DASHBOARD);
                    } else if (text === DRAWER_LABEL_RECOMMENDATIONS) {
                      history.push(ROUTE_RECOMMENDATION_DETAILS);
                    } else if (text === DRAWER_LABEL_ADD_TOKEN) {
                      history.push(ROUTE_ADD_NEW_CLOUD_PROVIDER);
                    } else if (
                      text === DRAWER_LABEL_CROSS_CLOUD_RECOMMENDATIONS
                    ) {
                      history.push(ROUTE_CROSS_CLOUD_RECOMMENDATIONS);
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {text === DRAWER_LABEL_DASHBOARD ? (
                      <>
                        <i
                          class="fi fi-rr-dashboard-panel"
                          style={ICON_STYLE}
                        />
                      </>
                    ) : text === DRAWER_LABEL_RECOMMENDATIONS ? (
                      <>
                        <i class="fi fi-rr-rectangle-list" style={ICON_STYLE} />
                      </>
                    ) : text === DRAWER_LABEL_CROSS_CLOUD_RECOMMENDATIONS ? (
                      <>
                        <i class="fi fi-rr-shuffle" style={ICON_STYLE} />
                      </>
                    ) : (
                      <>
                        <i class="fi fi-rr-square-plus" style={ICON_STYLE} />
                      </>
                    )}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List>
        <Divider />
        <ConfirmationDialog
          open={dialogOpen}
          title={"Are you sure you want to log out?"}
          onClose={() => {
            setDialogOpen(false);
          }}
          onNegativeBtnClick={() => {
            setDialogOpen(false);
          }}
          onPositiveBtnClick={() => {
            queryClient.removeQueries();
            dispatch(logout());
          }}
        />
        <List style={{ position: "absolute", bottom: 0 }}>
          {["Logout"].map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              sx={{ display: open ? "block" : "inline" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  // px: 2.5,
                }}
                onClick={() => {
                  if (text === "Logout") {
                    setDialogOpen(true);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <>
                    <i class="fi fi-rr-sign-out-alt" style={ICON_STYLE} />
                  </>
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box style={modalStyle}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <Typography id="modal-modal-title" variant="h4" fontWeight="bold">
                Help & Issue Board
              </Typography>
              <IconButton aria-label="close" onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography id="modal-modal-description" sx={{ color: "gray" }}>
              Frequently asked questions :
            </Typography>
            <form onSubmit={handleSubmit(submitFeedback)}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    {FEEDBACK_QUESTIONS.map((question) => (
                      <Controller
                        control={control}
                        name={`response_${question.id}`}
                        render={({
                          field,
                          fieldState: { error: fieldError },
                        }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                error={!!fieldError}
                                checked={field.value}
                                name={`response_${question.id}`}
                                {...field}
                              />
                            }
                            label={question.question}
                          />
                        )}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
                <FormControl>
                  <FormLabel style={{ marginBottom: "10px" }}>Other</FormLabel>
                  <InputField
                    control={control}
                    errors={errors}
                    name="other_issues"
                    label="Issue Board"
                    placeholder="write your issue here"
                    multiline
                    rows={5}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel style={{ marginBottom: "10px" }}>
                    Suggest features for future enhancements
                  </FormLabel>
                  <InputField
                    control={control}
                    errors={errors}
                    name="suggestions"
                    label="Feature Recommendations"
                    placeholder="write your recommendations"
                    multiline
                    rows={5}
                  />
                  {errors.root && (
                    <p style={{ color: "red", marginBlock: 0 }}>
                      {errors.root.message}
                    </p>
                  )}
                </FormControl>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                gap={2}
                marginTop={4}
              >
                <Button variant="outlined" onClick={handleModalClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    submitFeedbackMutation.isPending ||
                    submitFeedbackMutation.isError ||
                    submitFeedbackMutation.isSuccess
                  }
                >
                  Continue
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
      <Snackbar
        open={
          submitFeedbackMutation.isError || submitFeedbackMutation.isSuccess
        }
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        TransitionComponent={Slide}
        onClose={() => {
          submitFeedbackMutation.reset();
          handleModalClose();
        }}
      >
        <Alert
          onClose={handleClose}
          {...(submitFeedbackMutation.error
            ? { severity: "error" }
            : submitFeedbackMutation.isSuccess
            ? { severity: "success" }
            : null)}
          variant="filled"
        >
          {submitFeedbackMutation.error?.message ?? submitFeedbackMutation.data}
        </Alert>
      </Snackbar>
    </Box>
  );
}
