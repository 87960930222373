import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormLabel,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@material-ui/icons/Menu";
import { Circle, UploadFileOutlined } from "@mui/icons-material";
import CloudIcon from "@mui/icons-material/CloudOutlined";
import { MailOutline } from "@material-ui/icons";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";

import useProfile from "../../hooks/useProfile";
import { SnackBarProvider } from "../profile";
import { useTeam } from "../../hooks/useTeam";
import { SolidButton } from "../../ui/SolidButton";
import { Chip } from "../../ui/Chip";
import InputField from "../InputField/InputField";
import leftHalfCloud from "../../assets/half_cloud_left.svg";
import rightHalfCloud from "../../assets/half_cloud_right.svg";
import ResetPassword from "./ResetPassword";
import GradientCircleCheckIcon from "../../ui/GradientCircleCheckIcon";

export const PROFILE_FORM_MODES = {
  INITIAL: "initial",
  EDIT: "edit",
  RESET_PASSWORD: "reset-password",
};

const LogoModal = ({ open, handleClose }) => {
  const {
    control,
    watch,
    formState: { isValid, isSubmitting },
    clearErrors,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      organization_logo: null,
    },
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const snackbarContext = useContext(SnackBarProvider);

  const { updateOrgLogoMutation } = useTeam();

  const handleLogoSubmit = (data) => {
    updateOrgLogoMutation.mutate(data, {
      onSuccess: (data) => {
        snackbarContext.handleShowSnackbar(
          data.message || "Organization logo updated successfully",
          "success",
        );
        handleClose();
      },
      onError: (error) => {
        snackbarContext.handleShowSnackbar(
          error.message || "Something went wrong",
          "error",
        );
      },
    });
  };

  useEffect(() => {
    const subscription = watch((value) =>
      setSelectedFile(value.organization_logo),
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (open.value) {
      clearErrors();
      setSelectedFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open.value]);

  return (
    <Dialog open={open.value} onClose={handleClose} disableEnforceFocus={false}>
      <DialogContent dividers>
        {open.event === "delete" ? (
          <Box
            sx={{
              padding: "70px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <DeleteIcon color="primary" sx={{ fontSize: "40px" }} />
            <Typography variant="h5" fontWeight="bold">
              Remove current logo?
            </Typography>
            <Box
              sx={{
                mt: "20px",
                display: "flex",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                {updateOrgLogoMutation.isPending ? (
                  <CircularProgress size={24} style={{ color: "white" }} />
                ) : (
                  "Continue"
                )}
              </Button>
            </Box>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(handleLogoSubmit)}>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  border: "1px dashed #C4C4C4",
                  display: "grid",
                  placeItems: "center",
                  height: "340px",
                  width: "421px",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    textAlign: "center",
                  }}
                >
                  <Box sx={{ mb: "20px" }}>
                    <UploadFileIcon fontSize="large" color="primary" />
                  </Box>
                  <Typography variant="h5">
                    Drag and Drop to upload <br /> or{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#2196f3",
                        fontWeight: "bold",
                      }}
                    >
                      Browse
                    </span>
                  </Typography>
                </Box>
                <InputField
                  control={control}
                  type="file"
                  name="organization_logo"
                  rules={{
                    required: "Organization Logo is required",
                    validate: (value) => {
                      const acceptedFormats = ["png", "jpg", "jpeg"];
                      const fileExtension = value?.name
                        ?.split(".")
                        .pop()
                        .toLowerCase();
                      if (!acceptedFormats.includes(fileExtension)) {
                        return "Invalid file format. Only png, jpg, jpeg files are allowed.";
                      }
                      return true;
                    },
                  }}
                  customStyle={{
                    height: "340px",
                    width: "421px",
                    textAlign: "center",
                    opacity: 0,
                  }}
                />
              </Box>
              {selectedFile?.name ? (
                <Box
                  sx={{
                    mt: "20px",
                    display: "grid",
                    placeItems: "center",
                    border: "2px dashed #C4C4C4",
                    padding: "10px",
                    background: "#F5F5F5",
                    color: "primary",
                  }}
                >
                  <Tooltip title={selectedFile?.name}>
                    <Typography
                      style={{
                        maxWidth: "401px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      Selected File:{" "}
                      <Typography
                        color="primary"
                        fontWeight="bold"
                        component="span"
                      >
                        {selectedFile?.name}
                      </Typography>
                    </Typography>
                  </Tooltip>
                </Box>
              ) : null}

              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={
                    !isValid || isSubmitting || updateOrgLogoMutation.isPending
                  }
                >
                  {updateOrgLogoMutation.isPending ? (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

const ProfileAndPlanForAdmin = ({ getAuthenticatedUserQuery }) => {
  const authenticatedUserData = getAuthenticatedUserQuery.data;
  const currentSubscription = authenticatedUserData.organization?.subscription_status;

  const [profileFormMode, setProfileFormMode] = useState(
    PROFILE_FORM_MODES.INITIAL,
  );
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      first_name: authenticatedUserData?.first_name,
      last_name: authenticatedUserData?.last_name,
      email: authenticatedUserData?.email,
      organization_name: authenticatedUserData?.organization.name,
    },
    disabled:
      profileFormMode === PROFILE_FORM_MODES.INITIAL ||
      profileFormMode === PROFILE_FORM_MODES.RESET_PASSWORD,
    mode: "onChange",
  });

  const [showLogoDialog, setShowLogoDialog] = useState({
    value: false,
    event: null,
  });

  const handleLogoDialogClose = () => {
    setShowLogoDialog({
      value: false,
      event: showLogoDialog.event,
    });
  };
  const handleLogoDialogOpen = (event) => {
    setShowLogoDialog({
      value: true,
      event,
    });
  };

  const { updateUserProfileMutation } = useProfile();
  const snackbarContext = useContext(SnackBarProvider);

  const handleUpdateProfile = async (data) => {
    const paylod = {
      first_name: data.first_name,
      last_name: data.last_name,
    };

    updateUserProfileMutation.mutate(paylod, {
      onSuccess: (data) => {
        snackbarContext.handleShowSnackbar(
          data.message || "User profile updated successfully",
          "success",
        );
        setProfileFormMode(PROFILE_FORM_MODES.INITIAL);
      },
      onError: (error) => {
        snackbarContext.handleShowSnackbar(
          error.message || "Something went wrong",
          "error",
        );
      },
    });
  };

  return (
    <Box style={{ display: "grid", gridTemplateColumns: "3fr 1fr" }}>
      <Box style={{ marginRight: "20px" }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            borderRadius: "5px",
            gap: "20px",
          }}
        >
          <Card
            style={{
              padding: "30px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Card
              sx={{
                maxWidth: 328,
                border: "2px solid #0078d4",
                borderRadius: 4,
              }}
            >
              <CardHeader
                title={currentSubscription.plan_name}
                sx={{ textAlign: "center", paddingX: 4 }}
              />
              <CardContent
                sx={{ textAlign: "center", paddingTop: 0, paddingX: 4 }}
              >
                <Divider sx={{ marginBottom: 4 }} />
                <Typography variant="h4">{currentSubscription.price}</Typography>
                <Typography color="primary" fontWeight="bold">
                  {currentSubscription.description}
                </Typography>
                <Divider sx={{ marginY: 4 }} />
                <SolidButton>Change Plan</SolidButton>
              </CardContent>
            </Card>
          </Card>
          <Card
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 20px",
              }}
            >
              <CardHeader
                title="Transaction History"
                sx={{ textAlign: "center" }}
              />
              <Box style={{ display: "flex", gap: 2 }}>
                <IconButton aria-label="Menu">
                  <UploadFileOutlined />
                </IconButton>
                <IconButton aria-label="Menu">
                  <MailOutline />
                </IconButton>
              </Box>
            </Box>
            <CardContent sx={{ paddingTop: 0, paddingX: 4 }}>
              <Box
                maxHeight="300px"
                style={{
                  overflowY: "auto",
                  marginBottom: "20px",
                  padding: "0 10px",
                }}
              >
                {currentSubscription.transaction_history?.map((transaction) => {
                  return (
                    <Card
                      sx={{
                        borderRadius: 5,
                        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                        marginY: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                          padding: "10px 10px 10px 44px",
                        }}
                      >
                        <Typography variant="h5">{transaction.plan_name}</Typography>
                      </Box>
                      <CardContent>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box display="flex" gap={2} alignItems="center">
                            <Circle color="primary" size={30} />
                            <Box>
                              <Typography fontWeight="bold">{transaction.name}</Typography>
                              <Typography component="p">
                                {transaction.date} {transaction.time}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" gap={2}>
                            {/*TODO: need to replace with actual icon*/}
                            <MenuIcon />
                            <Typography fontWeight="bold">{transaction.amount}</Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>);
                })}
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            borderRadius: "5px",
          }}
        >
          <Card
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
          >
            <CardHeader title="Included in your plan" />
            <CardContent>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Box>
                    <Typography fontWeight="bold">
                      Monthly track cloud spend: {" "}
                      <Typography fontWeight="normal" component={"span"}>
                        {currentSubscription.features.monthly_tracked_cloud_spend}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Typography fontWeight="bold">
                    Usage Analytics:{" "}
                  </Typography>
                  {currentSubscription.features.usage_analytics ? (
                    <GradientCircleCheckIcon height={"20px"} width={"20px"} />) : null}
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Box>
                    <Typography fontWeight="bold">
                      Accounts: {" "}
                      <Typography
                        fontWeight="normal"
                        component={"span"}
                      >
                        {currentSubscription.features.accounts}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Typography fontWeight="bold">
                    Reports:
                  </Typography>
                  {currentSubscription.features.usage_analytics ? (
                    <GradientCircleCheckIcon height={"20px"} width={"20px"} />
                  ) : null}
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Box>
                    <Typography fontWeight="bold">
                      Resource Optimization:{" "}
                      <Typography fontWeight="normal" component={"span"}>
                        {currentSubscription.features.resource_optimization}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Box>
                    <Typography fontWeight="bold">
                      API keys: {" "}
                      <Typography
                        fontWeight="normal"
                        component={"span"}
                      >{currentSubscription.features.accounts}</Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Box>
                    <Typography fontWeight="bold">
                      Cloud Support: {" "}
                      <Typography
                        fontWeight="normal"
                        component={"span"}
                      >{currentSubscription.features.supported_clouds}</Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
          {/*TODO: Currently not coming the recommended plan from api*/}
          {currentSubscription.recommended_plan ? (<Card
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
              maxHeight: "400px",
            }}
          >
            <Box
              style={{ display: "grid", gridTemplateRows: "1fr 2.5fr 60px" }}
            >
              <Box>
                <Box sx={{ textAlign: "center", paddingY: 4 }}>
                  <Typography variant="h5">Recommended Plans</Typography>
                </Box>
                <Divider />
              </Box>
              <CardContent style={{ position: "relative" }}>
                <Box
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                    padding: "20px",
                  }}
                >
                  <img
                    src={rightHalfCloud}
                    alt=""
                    width="64px"
                    height="56px"
                    style={{ position: "absolute", left: 0, bottom: -10 }}
                  />
                  <img
                    src={leftHalfCloud}
                    alt=""
                    width="64px"
                    height="56px"
                    style={{ position: "absolute", top: 0, right: 0 }}
                  />
                  <Typography
                    variant="h4"
                    style={{ color: "green", textAlign: "center" }}
                    fontWeight="bold"
                  >
                    Save $110 with YEARLY plan
                  </Typography>
                </Box>
              </CardContent>
              <Box
                style={{
                  background: "white",
                  position: "relative",
                  zIndex: 10,
                }}
              >
                <Divider />
                <Box
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                  }}
                >
                  <Button>Explore &nbsp;&nbsp;&nbsp;&nbsp;> </Button>
                </Box>
              </Box>
            </Box>
          </Card>) : null}
        </Box>
      </Box>
      <Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Box textAlign="right">
            <Typography fontWeight="bold" color={"primary"}>{currentSubscription.price}</Typography>
            <Typography fontWeight="bold">
              Next payment on <Typography component={"span"}
                                          color={"primary"}
                                          fontWeight={"bold"}>{currentSubscription.expiry_date}</Typography>
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            margin: "20px 0 40px 0",
          }}
        >
          <Tooltip title={`${authenticatedUserData?.first_name} ${authenticatedUserData?.last_name}`}>
            <Typography fontWeight="bold" maxWidth={"20ch"} overflow={"hidden"} textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}>
              Hi,{" "}
              {authenticatedUserData?.first_name +
                " " +
                authenticatedUserData?.last_name}
            </Typography>
          </Tooltip>
          <Chip
            label={authenticatedUserData?.role?.name}
            sx={{
              fontWeight: "bold",
              backgroundColor: "rgba(33, 150, 243, 0.3)",
            }}
          />
        </Box>

        {profileFormMode === PROFILE_FORM_MODES.RESET_PASSWORD ? (
          <ResetPassword
            setProfileFormMode={setProfileFormMode}
            snackbarContext={snackbarContext}
          />
        ) : (
          <>
            <form onSubmit={handleSubmit(handleUpdateProfile)}>
              <Box style={{ display: "grid", gap: "20px" }}>
                <InputField
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "The First Name field is required.",
                    },
                  }}
                  name="first_name"
                  placeholder="First Name"
                  label="First Name"
                />
                <InputField
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "The Last Name field is required.",
                    },
                  }}
                  name="last_name"
                  placeholder="Last Name"
                  label="Last Name"
                />
                <InputField
                  control={control}
                  disabled
                  rules={{
                    required: {
                      value: true,
                      message: "The email field is required.",
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  }}
                  style={{ cursor: "not-allowed" }}
                  name="email"
                  placeholder="Email"
                  label="User Email ID"
                  type="email"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <InputField
                    control={control}
                    disabled
                    rules={{
                      required: {
                        value: true,
                        message: "The Organization Name field is required.",
                      },
                    }}
                    style={{
                      width: !authenticatedUserData.organization.logo
                        ? "80%"
                        : "100%",
                      cursor: "not-allowed",
                    }}
                    name="organization_name"
                    placeholder="Organization Name"
                    label="Organization Name"
                    type="text"
                  />
                  {!authenticatedUserData.organization.logo ? (
                    <Button
                      variant="outlined"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={handleLogoDialogOpen}
                    >
                      Upload Logo
                    </Button>
                  ) : null}
                </Box>
                {authenticatedUserData.organization.logo ? (
                  <Box
                    sx={{
                      border: "1px solid #C4C4C4",
                      borderRadius: "5px",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      opacity:
                        profileFormMode === PROFILE_FORM_MODES.RESET_PASSWORD ||
                        profileFormMode === PROFILE_FORM_MODES.INITIAL
                          ? 0.5
                          : 1,
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: "-10px",
                        top: "-10px",
                        background: "white",
                        px: "10px",
                        fontSize: "1rem",
                        scale: "0.75",
                      }}
                    >
                      <FormLabel>Organization Logo</FormLabel>
                    </Box>
                    {profileFormMode === PROFILE_FORM_MODES.RESET_PASSWORD ||
                    profileFormMode === PROFILE_FORM_MODES.INITIAL ? null : (
                      <Box
                        sx={{
                          position: "absolute",
                          right: "10px",
                          top: "-10px",
                        }}
                      >
                        <EditIcon
                          sx={{
                            fontSize: "17px",
                            background: "white",
                            border: "1px solid #C4C4C4",
                            borderRadius: "3px",
                            filter:
                              "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15))",
                            cursor: "pointer",
                          }}
                          onClick={handleLogoDialogOpen}
                        />
                        <DeleteIcon
                          sx={{
                            marginLeft: "4px",
                            fontSize: "17px",
                            background: "white",
                            border: "1px solid #C4C4C4",
                            borderRadius: "3px",
                            filter:
                              "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15))",
                            cursor: "pointer",
                          }}
                          onClick={() => handleLogoDialogOpen("delete")}
                        />
                      </Box>
                    )}
                    <Box
                      component="img"
                      width="100%"
                      height="60px"
                      alt="organization logo"
                      style={{
                        objectFit: "contain",
                      }}
                      src={authenticatedUserData.organization.logo}
                    />
                  </Box>
                ) : null}
              </Box>
              {profileFormMode === PROFILE_FORM_MODES.EDIT ? (
                <Box
                  style={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                    marginTop: "40px",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() =>
                      setProfileFormMode(PROFILE_FORM_MODES.INITIAL)
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      padding: "4px 24px",
                      border:
                        !isValid || isSubmitting
                          ? "rgba(0, 0, 0, 0.12)"
                          : "1px solid #2196f3",
                    }}
                    type="submit"
                    disabled={
                      !isValid ||
                      isSubmitting ||
                      updateUserProfileMutation.isPending
                    }
                  >
                    {updateUserProfileMutation.isPending ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Box>
              ) : null}
            </form>
            {profileFormMode === PROFILE_FORM_MODES.INITIAL ? (
              <Box
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                  marginTop: "24px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => setProfileFormMode(PROFILE_FORM_MODES.EDIT)}
                >
                  Edit Profile
                </Button>
                <Button
                  variant="outlined"
                  onClick={() =>
                    setProfileFormMode(PROFILE_FORM_MODES.RESET_PASSWORD)
                  }
                >
                  Reset Password
                </Button>
              </Box>
            ) : null}
          </>
        )}
      </Box>
      <LogoModal open={showLogoDialog} handleClose={handleLogoDialogClose} />
    </Box>
  );
};

export default ProfileAndPlanForAdmin;
