import React from "react";
import styled from "@emotion/styled/macro";
import { Box, Divider, Typography } from "@mui/material";
import { APP_BAR_HEIGHT } from "../../ui/AppBar";
import { USER_FACING_PRODUCT_NAME } from "../../utils/constants";
import { GradeintTextStyle, HeaderText } from "./LandingPage";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import FeatureSectionImageLaptopAndDesktop from "../../assets/png/feature_section_image_laptop_desktop.png";
import FeatureSectionImageMobile from "../../assets/png/feature_section_image_phone.png";
import FeatureSectionImageTablet from "../../assets/png/feature_section_image_tablet.png";

export const Title = styled(Typography)`
  font-style: normal;
  font-weight: 800;
  text-align: center;
`;

export const CARD_HEADING_STYLE = {
  whiteSpace: "break-spaces",
  textAlign: "center",
  fontSize: {
    mobile: "20px",
    tablet: "24px",
    laptop: "32px",
    desktop: "34px",
  },
};

export const CARD_DESC_STYLE = {
  fontSize: {
    mobile: "15px",
    tablet: "18px",
    laptop: "18px",
    desktop: "24px",
  },
};

export const CardHeading = styled(Typography)`
  color: #3a3a3a;

  /* font-feature-settings: "liga" off, "clig" off; */

  /* typography/h4 */
  /* font-style: normal; */
  font-weight: 400;
  line-height: 123.5%; /* 41.99px */
  letter-spacing: 0.25px;
`;

export const CardDescription = styled(Typography)`
  color: #3a3a3a;

  /* font-feature-settings: "liga" off, "clig" off; */

  /* typography/h4 */
  /* font-style: normal; */
  font-weight: 400;
  line-height: 123.5%; /* 41.99px */
  letter-spacing: 0.25px;
`;

export default function FeatureSection() {
  const breakpoint = useBreakpoint();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: {
          desktop: "1400px",
          laptop: "1200px",
          tablet: "750px",
        },
        margin: { tablet: "0 auto" },
        mt: {
          // this addition of dimension was done in order to scroll to this header correctly
          // for unknown reasons, scrolling to other headers
          // didnt cause any problem on mobile responsive.
          mobile: `${APP_BAR_HEIGHT + 44}px`,
          tablet: "114px",
          laptop: "100px",
          desktop: "127px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          // width: "100%",
        }}
      >
        {/* <GradeintText variant="h6">Features</GradeintText>
        <Title variant="h6">&nbsp;Of {USER_FACING_PRODUCT_NAME}</Title> */}
        <HeaderText variant="h6">
          <span style={GradeintTextStyle}>Features</span>
          &nbsp;Of {USER_FACING_PRODUCT_NAME}
        </HeaderText>
      </Box>
      <Box
        sx={{
          pt: "40px",
          mb: {
            tablet: "80px",
            laptop: "107px",
            desktop: "104px",
          },
          // px: {
          //   mobile: "25px",
          //   tablet: "45px",
          //   laptop: "60px",
          //   desktop: "88px",
          // },
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: {
              mobile: "100%",
              laptop: "80%",
              desktop: "100%",
            },
          }}
          component="img"
          src={
            breakpoint === "mobile"
              ? FeatureSectionImageMobile
              : breakpoint === "tablet"
              ? FeatureSectionImageTablet
              : FeatureSectionImageLaptopAndDesktop
          }
        />
      </Box>
      <Divider
        sx={{
          height: "2px",
          background: "linear-gradient(90deg, #9ED2FA 0%, #10CCD2 100%)",
          width: "100%",
          display: {
            mobile: "none",
            tablet: "block",
            laptop: "block",
            desktop: "block",
          },
        }}
      />
    </Box>
  );
}
