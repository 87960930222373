import { useQuery } from "@tanstack/react-query";

import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { fetch, store } from "../utils/httpUtil";
import { CLOUD_PROVIDER_AWS } from "../utils/constants";
import {
  CACHED_DATA_CACHE_TIME,
  CACHED_DATA_STALE_TIME,
} from "../config/config";
import { QUERY_KEY } from "../utils/queryKeys";

const getDashBoardRecommendations = async (
  key_id = null,
  cloudProvider = null,
) => {
  try {
    let url = `/dashboard/recommendations/`;
    if (key_id && cloudProvider) {
      url = `/dashboard/cloud-based-recommendations/?cloud_name=${cloudProvider}&key_id=${key_id}`;
    }
    const resp = await fetch(url);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useDashBoardRecommendationsV2 = (
  key_id = null,
  cloudProvider = null,
) => {
  let queryKey = [QUERY_KEY.DASHBOARD_RECOMMENDATIONS];
  if (key_id && cloudProvider) {
    queryKey = [...queryKey, key_id, cloudProvider];
  }
  const getDashBoardRecommendationsQuery = useQuery({
    queryKey,
    queryFn: () => getDashBoardRecommendations(key_id, cloudProvider),
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getDashBoardRecommendationsQuery };
};

const getCloudTypes = async () => {
  try {
    const resp = await fetch(`/dashboard/cloud-type/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetCloudTypes = () => {
  const getCloudTypesQuery = useQuery({
    queryKey: [QUERY_KEY.CLOUD_TYPES],
    queryFn: getCloudTypes,
    refetchOnWindowFocus: false,
    retry: 3,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return getCloudTypesQuery;
};

const getCloudKeys = async (cloudProvider) => {
  try {
    const resp = await fetch(`/recommendations/list-keys/${cloudProvider}/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetCloudKeys = (cloudProvider) => {
  const getCloudKeysQuery = useQuery({
    queryKey: [QUERY_KEY.CLOUD_KEYS, cloudProvider],
    queryFn: () => getCloudKeys(cloudProvider),
    enabled: !!cloudProvider,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return getCloudKeysQuery;
};

const getCloudServices = async () => {
  try {
    const resp = await fetch(`/dashboard/list-cloud-services/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetCloudServices = () => {
  const getCloudServicesQuery = useQuery({
    queryKey: [QUERY_KEY.RECOMMENDATIONS_CLOUD_SERVICES],
    queryFn: getCloudServices,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return getCloudServicesQuery;
};

const getRecommendationTypes = async () => {
  try {
    const resp = await fetch(`/dashboard/list-recommendation-types/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetRecommendationTypes = () => {
  const getRecommendationTypesQuery = useQuery({
    queryKey: [QUERY_KEY.RECOMMENDATIONS_TYPES],
    queryFn: getRecommendationTypes,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return getRecommendationTypesQuery;
};

export const getRecommendations = async (
  cloudProvider,
  accessKeyId,
  service,
  recommendation_type,
  costSaving,
) => {
  try {
    let payload = {
      cloud_name: cloudProvider,
      key_id: accessKeyId,
      service: service,
      recommendation_type: recommendation_type,
    };

    if (cloudProvider === CLOUD_PROVIDER_AWS) {
      payload["cost_strategy"] = costSaving;
    }

    const resp = await store(`/recommendations/`, payload);

    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const getDropDownOptions = async () => {
  try {
    const resp = await fetch(`/dashboard/get-dropdown-for-recommendations/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useRecommendationsV2 = (dropDownData) => {
  // since this is post call, this needs to mutation but we are using query for following reasons
  // 1. we need to cache the data (complex to impelement in mutation way, need lot handling states)
  // 2. we are not doing any update when mutation is successful

  const getRecommendationDataQuery = useQuery({
    queryKey: [QUERY_KEY.RECOMMENDATIONS_RESULTS, dropDownData],
    queryFn: () =>
      getRecommendations(
        dropDownData.cloudProvider,
        dropDownData.accessKeyId,
        dropDownData.service,
        dropDownData.recommendationType,
        dropDownData.costSaving,
      ),
    refetchOnWindowFocus: false,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
    retry: 2,
    enabled: Boolean(
      dropDownData.cloudProvider &&
      dropDownData.accessKeyId &&
      dropDownData.service &&
      dropDownData.recommendationType &&
      dropDownData.costSaving,
    ),
  });

  return getRecommendationDataQuery;
};
