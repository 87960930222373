export const QUERY_KEY = {
  TOTAL_COST: "total-cost",
  CLOUD_COST_TREND_FOR_ALL: "cloud-cost-trend-for-all",
  TOP_5_ACC_COMPUTE_EFFECIENCY_V2: "top-5-acc-compute-effeciency-v2",
  ACCOUNT_COMPUTE_EFFECIENCY_V2: "account-compute-effeciency-v2",
  TOKEN_LIST_V2: "token-list-v2",
  COST_BY_SERVICE_V2: "cost-by-service-v2",
  COST_BY_REGION_V2: "cost-by-region-v2",
  COST_BY_ACCOUNT_V2: "cost-by-account-v2",
  DASHBOARD_RECOMMENDATIONS: "dashboard-recommendation",
  CLOUD_TYPES: "recommendations-cloud-types",
  CLOUD_KEYS: "recommendations-cloud-keys",
  RECOMMENDATIONS_CLOUD_SERVICES: "recommendations-cloud-services",
  RECOMMENDATIONS_TYPES: "recommendations-types",
  RECOMMENDATIONS_RESULTS: "recommendations-results",
  CC_CLOUD_REGIONS: "cc-cloud-regions",
  CC_RECOMMENDATIONS_RESULTS: "cc-recommendations-results",
  AWS_CLOUD_FORMATION: "aws-cloud-formation",
  AWS_CLOUD_FORMATION_URL: "aws-cloud-formation-url",
  CHECK_IF_DATA_FETCHED: "check-if-data-fetched",
  USER_ROLES: "user-roles",
  TEAM_MEMBERS: "team-members",
  INVITED_MEMBER_DATA: "invited-member-data",
  AUTHENTICATED_USER_INFO: "authenticated-user-info",
  AVAILABLE_PRICING_PLANS: "available-pricing-plans",
};
