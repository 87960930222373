import React, { useState, useContext } from "react";
import {
  Alert,
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Link,
  Typography,
  FormLabel, Tooltip,
} from "@mui/material";
import CloudIcon from "@mui/icons-material/CloudOutlined";
import { ChevronRight } from "@material-ui/icons";
import { useForm } from "react-hook-form";

import useProfile from "../../hooks/useProfile";
import usePricing from "../../hooks/usePricing";
import ResetPassword from "./ResetPassword";
import { SnackBarProvider } from "../profile";
import { SolidButton } from "../../ui/SolidButton";
import { Chip } from "../../ui/Chip";
import InputField from "../InputField/InputField";
import { PROFILE_FORM_MODES } from "./ProfileAndPlanForAdmin";
import GradientCircleCheckIcon from "../../ui/GradientCircleCheckIcon";

const ExplorePlansSection = ({ plans, isLoading, error }) => {

  if (isLoading) {
    return (
      <Box padding={"20px"} textAlign={"center"}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box paddingX={"20px"}>
        <Alert severity="warning" sx={{ my: 1 }}>
          {error?.message || "Something went wrong"}
        </Alert>
      </Box>
    );
  }


  return (
    <Box
      style={{
        display: "flex",
        gap: "20px",
        overflowX: "auto",
        paddingBottom: "10px",
        maxWidth: "100%",
      }}
    >
      {plans.map((plan) => {
        return (<Card
          style={{ flexShrink: 0, maxWidth: "272px", minHeight: "200px" }}
        >
          <CardContent
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="bold"
                textAlign="center"
              >
                {plan.plan_name}
              </Typography>
              <Divider style={{ margin: "0 0 20px 0" }} />
              <Typography
                variant="body2"
                textAlign="center"
                style={{ padding: "0 20px" }}
              >
                {plan.description} <br /> use for just <Typography component={"span"} color={"primary"}
                                                                   fontWeight="bold">{plan.price}</Typography>
              </Typography>
            </Box>
            <Box style={{}}>
              <Link
                href="/"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                Explore <ChevronRight />
              </Link>
            </Box>
          </CardContent>
        </Card>);
      })
      }
    </Box>);
};

const ProfileAndPlanForMember = ({ getAuthenticatedUserQuery }) => {
  const authenticatedUserData = getAuthenticatedUserQuery.data;
  const currentSubscription = authenticatedUserData.organization?.subscription_status;

  const { getAvailablePricingPlansQuery } = usePricing();

  const [profileFormMode, setProfileFormMode] = useState(
    PROFILE_FORM_MODES.INITIAL,
  );
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      first_name: authenticatedUserData?.first_name,
      last_name: authenticatedUserData?.last_name,
      email: authenticatedUserData?.email,
      organization_name: authenticatedUserData?.organization.name,
    },
    disabled:
      profileFormMode === PROFILE_FORM_MODES.INITIAL ||
      profileFormMode === PROFILE_FORM_MODES.RESET_PASSWORD,
    mode: "onChange",
  });

  const { updateUserProfileMutation } = useProfile();
  const snackbarContext = useContext(SnackBarProvider);

  const handleUpdateProfile = (data) => {
    const paylod = {
      first_name: data.first_name,
      last_name: data.last_name,
    };

    updateUserProfileMutation.mutate(paylod, {
      onSuccess: (data) => {
        snackbarContext.handleShowSnackbar(
          data.message || "User profile updated successfully",
          "success",
        );
        setProfileFormMode(PROFILE_FORM_MODES.INITIAL);
      },
      onError: (error) => {
        snackbarContext.handleShowSnackbar(
          error.message || "Something went wrong",
          "error",
        );
      },
    });
  };

  return (
    <Box
      style={{
        display: "grid",
        gridTemplateColumns: "3fr 1fr",
        maxWidth: "100%",
        gap: "20px",
      }}
    >

      <Box
        sx={{
          borderRadius: "5px",
          gap: "20px",
        }}
      >
        <Card
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            padding: "30px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            gap: "20px",
          }}
        >
          <Card
            sx={{
              maxWidth: 328,
              border: "2px solid #0078d4",
              borderRadius: 4,
            }}
          >
            <CardContent
              sx={{ textAlign: "center", paddingTop: 0, paddingX: 4 }}
            >
              <Typography sx={{ my: 4 }} variant="h4">{currentSubscription.plan_name}</Typography>
              <Divider sx={{ marginBottom: 4 }} />
              <Typography variant="h4">{currentSubscription.price}</Typography>
              <Typography color="primary" fontWeight="bold">
                {currentSubscription.description}
              </Typography>
              <Divider sx={{ marginY: 4 }} />
              <SolidButton>Change Plan</SolidButton>
            </CardContent>
          </Card>
          <Card sx={{ boxShadow: "none" }}>
            <Typography fontWeight="bold" variant="h5">Included in your plan</Typography>
            <CardContent>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Box>
                    <Typography fontWeight="bold">
                      Monthly track cloud spend:{" "}
                      <Typography fontWeight="normal" component={"span"}>
                        {currentSubscription.features.monthly_tracked_cloud_spend}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Typography fontWeight="bold">
                    Usage Analytics:
                  </Typography>
                  <Typography fontWeight="normal" component={"span"}>
                    {currentSubscription.features.usage_analytics ? (
                      <GradientCircleCheckIcon height={"20px"} width={"20px"} />) : null}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Box>
                    <Typography fontWeight="bold">
                      Accounts: {" "}
                      <Typography
                        fontWeight="normal"
                        component={"span"}
                      >
                        {currentSubscription.features.accounts}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Typography fontWeight="bold">
                    Reports:
                  </Typography>
                  <Box>
                    {currentSubscription.features.usage_analytics ? (
                      <GradientCircleCheckIcon height={"20px"} width={"20px"} />
                    ) : null}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Box>
                    <Typography fontWeight="bold">
                      Resource Optimization:{" "}
                      <Typography fontWeight="normal" component={"span"}>
                        {currentSubscription.features.resource_optimization}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Box>
                    <Typography fontWeight="bold">
                      API keys: {" "}
                      <Typography
                        fontWeight="normal"
                        component={"span"}
                      >{currentSubscription.features.accounts}</Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CloudIcon
                    style={{
                      stroke: "transparent",
                      color: "#0090D1",
                    }}
                  />
                  <Box>
                    <Typography fontWeight="bold">
                      Cloud Support: {" "}
                      <Typography
                        fontWeight="normal"
                        component={"span"}
                      >{currentSubscription.features.supported_clouds}</Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Card>
      </Box>
      <Box sx={{
        gridColumn: "2",
        gridRow: "1 / span 2",
      }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Box textAlign="right">
            <Typography fontWeight="bold" color={"primary"}>{currentSubscription.price}</Typography>
            <Typography fontWeight="bold">
              Next payment on <Typography component={"span"} color={"primary"}
                                          fontWeight={"bold"}>{currentSubscription.expiry_date}</Typography>
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            margin: "20px 0 40px 0",
          }}
        >
          <Tooltip title={`${authenticatedUserData?.first_name} ${authenticatedUserData?.last_name}`}>
            <Typography fontWeight="bold" maxWidth={"20ch"} overflow={"hidden"} textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}>
              Hi,{" "}
              {authenticatedUserData?.first_name +
                " " +
                authenticatedUserData?.last_name}
            </Typography>
          </Tooltip>
          <Chip
            label={authenticatedUserData?.role?.name}
            sx={{
              fontWeight: "bold",
              backgroundColor: "rgba(33, 150, 243, 0.3)",
            }}
          />
        </Box>

        {profileFormMode === PROFILE_FORM_MODES.RESET_PASSWORD ? (
          <ResetPassword
            setProfileFormMode={setProfileFormMode}
            snackbarContext={snackbarContext}
          />
        ) : (
          <>
            <form onSubmit={handleSubmit(handleUpdateProfile)}>
              <Box style={{ display: "grid", gap: "20px" }}>
                <InputField
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "The First Name field is required.",
                    },
                  }}
                  name="first_name"
                  placeholder="First Name"
                  label="First Name"
                />
                <InputField
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "The Last Name field is required.",
                    },
                  }}
                  name="last_name"
                  placeholder="Last Name"
                  label="Last Name"
                />
                <InputField
                  control={control}
                  disabled
                  rules={{
                    required: {
                      value: true,
                      message: "The email field is required.",
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  }}
                  style={{ cursor: "not-allowed" }}
                  name="email"
                  placeholder="Email"
                  label="User Email ID"
                  type="email"
                />
                <InputField
                  control={control}
                  disabled
                  rules={{
                    required: {
                      value: true,
                      message: "The Organization Name field is required.",
                    },
                  }}
                  style={{
                    cursor: "not-allowed",
                  }}
                  name="organization_name"
                  placeholder="Organization Name"
                  label="Organization Name"
                  type="text"
                />
                {authenticatedUserData.organization.logo ? (
                  <Box
                    sx={{
                      border: "1px solid #C4C4C4",
                      borderRadius: "5px",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: "-10px",
                        top: "-10px",
                        background: "white",
                        px: "10px",
                        fontSize: "1rem",
                        scale: "0.75",
                      }}
                    >
                      <FormLabel>Organization Logo</FormLabel>
                    </Box>
                    <Box
                      component="img"
                      width="100%"
                      height="60px"
                      alt="organization logo"
                      style={{
                        objectFit: "contain",
                      }}
                      src={authenticatedUserData.organization.logo}
                    />
                  </Box>
                ) : null}
              </Box>
              {profileFormMode === PROFILE_FORM_MODES.EDIT ? (
                <Box
                  style={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                    marginTop: "40px",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() =>
                      setProfileFormMode(PROFILE_FORM_MODES.INITIAL)
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      padding: "4px 24px",
                      border:
                        !isValid || isSubmitting
                          ? "rgba(0, 0, 0, 0.12)"
                          : "1px solid #2196f3",
                    }}
                    type="submit"
                    disabled={
                      !isValid ||
                      isSubmitting ||
                      updateUserProfileMutation.isPending
                    }
                  >
                    {updateUserProfileMutation.isPending ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Box>
              ) : null}
            </form>
            {profileFormMode === PROFILE_FORM_MODES.INITIAL ? (
              <Box
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                  marginTop: "24px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => setProfileFormMode(PROFILE_FORM_MODES.EDIT)}
                >
                  Edit Profile
                </Button>
                <Button
                  variant="outlined"
                  onClick={() =>
                    setProfileFormMode(PROFILE_FORM_MODES.RESET_PASSWORD)
                  }
                >
                  Reset Password
                </Button>
              </Box>
            ) : null}
          </>
        )}
      </Box>
      <Card
        sx={{
          borderRadius: "5px",
        }}
      >
        <CardHeader title="Explore Other Plans" />
        <ExplorePlansSection plans={getAvailablePricingPlansQuery.data ?? []}
                             isLoading={getAvailablePricingPlansQuery.isLoading}
                             error={getAvailablePricingPlansQuery.error} />
      </Card>

    </Box>
  );
};

export default ProfileAndPlanForMember;
