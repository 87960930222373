import React, { useMemo } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Chip } from "../../../ui/Chip";
import {
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_OCI,
  RECOMMENDATION_TAGS,
  RECOMMENDATION_TYPE_OPTIMAL_V2,
} from "../../../utils/constants";

import ParallelArchitecture from "../../../assets/png/parallel.png";
import CrossArchitecture from "../../../assets/png/cross_cloud.svg";
import SameArchitecture from "../../../assets/png/same_architecture.png";
import OptimalRecommenationEmptyState from "../../../assets/svg/edge-cases/recommendations-error-state.svg";
import AllVmsOptimalState from "../../../assets/svg/edge-cases/all-vms-optimal-state.svg";

const columns = [
  {
    field: "tag",
    headerName: "Recommendation",
    minWidth: 200,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    renderCell: (value) => {
      const architectureType = value.value;

      let logoUrl = null;
      if (architectureType === "parallel_architecture") {
        logoUrl = ParallelArchitecture;
      } else if (architectureType === "cross_architecture") {
        logoUrl = CrossArchitecture;
      } else if (architectureType === "same_architecture") {
        logoUrl = SameArchitecture;
      }

      return (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            height: "100%",
          }}
        >
          <Box
            component="img"
            src={logoUrl}
            alt="Architecture logo"
            width="15px"
            height="15px"
            style={{
              rotate: architectureType !== "cross_architecture" ? "90deg" : "",
            }}
          />
          <Typography variant="body2">
            {RECOMMENDATION_TAGS[value.value]}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "machine_type",
    headerName: "Machine Type",
    headerClassName: "header-row",
    minWidth: 140,
    flex: 1,
    filterable: true,
  },
  {
    field: "vcpu",
    headerName: "vCPUs",
    headerClassName: "header-row",
    minWidth: 100,
    flex: 1,
    filterable: true,
  },
  {
    field: "memory",
    headerName: "Memory (GiB)",
    headerClassName: "header-row",
    minWidth: 120,
    flex: 1,
    filterable: true,
  },
  {
    field: "machine_price",
    headerName: "Price per Month",
    headerClassName: "header-row",
    minWidth: 130,
    flex: 1,
    filterable: true,
    sortComparator: (v1, v2) => {
      return v1.num - v2.num;
    },
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {value.row.machine_price?.str}
        </Box>
      );
    },
  },
  {
    field: "saving",
    headerName: "Saving Opportunity ($)",
    headerClassName: "header-row",
    minWidth: 250,
    flex: 1,
    filterable: true,
    sortComparator: (v1, v2) => {
      return v1.num - v2.num;
    },
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          Save {value.row.saving?.str}
          <Chip
            sx={{
              backgroundColor: "transparent",
              color: "#358A01",
              border: "1px solid grey",
              borderRadius: "8px",
              padding: "0px",
              margin: "0px",
              fontWeight: 600,
              minWidth: "70px",
            }}
            label={`SAVE ${value.row.percentage_saved}%`}
          />
        </Box>
      );
    },
  },
  {
    field: "architecture",
    headerName: "Architecture",
    headerClassName: "header-row",
    minWidth: 130,
    flex: 1,
    filterable: true,
  },
];

const EmptyStateView = ({ selectedVm, areAllVmsOptimal }) => {
  if (areAllVmsOptimal) {
    return (
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 4,
          textAlign: "center",
          width: "100%",
          height: "100%",
          margin: "auto",
          boxShadow: "none",
        }}
      >
        <Box
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          <Box
            component="img"
            src={AllVmsOptimalState}
            style={{ width: "30%" }}
          />
          <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
            Woohoo! Your current key is perfect. Consider selecting another key.
          </Typography>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 4,
        textAlign: "center",
        width: "100%",
        height: "100%",
        margin: "auto",
        boxShadow: "none",
      }}
    >
      {(!selectedVm?.recommended_action &&
        selectedVm?.percentage_saved === 0) ||
      selectedVm?.recommended_action === RECOMMENDATION_TYPE_OPTIMAL_V2 ? (
        <Box
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          <Box
            component="img"
            src={OptimalRecommenationEmptyState}
            style={{ width: "30%" }}
          />
          <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
            This instance is optimal. Try selecting an alternative VM.
          </Typography>
        </Box>
      ) : (
        <Box
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          <Box
            component="img"
            src={OptimalRecommenationEmptyState}
            style={{ width: "30%" }}
          />
          <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
            Woops! There's nothing to show right now. Try selecting an
            alternative VM.
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "end",
        backgroundColor: "#F5F5F5",
      }}
    >
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const CrossCloudOptimizeView = ({
  selectedVm,
  cloudProvider,
  areAllVmsOptimal,
}) => {
  const {
    rows,
    maxSavingsPossibleFromRecommendation,
    missingRecommendationsByTag,
  } = useMemo(() => {
    const recommendations =
      selectedVm?.recommendations.map((recommendation, index) => ({
        id: index + 1,
        ...recommendation,
      })) ?? [];

    const groupByTag = Object.groupBy(recommendations, ({ tag }) => tag);

    const sortByDescWrtSavingPercent = Object.entries(groupByTag).flatMap(
      ([tag, recommendations]) => {
        const descBySavingPercent = recommendations.sort((a, b) => {
          return b.percentage_saved - a.percentage_saved;
        });
        return descBySavingPercent[0];
      }
    );

    const availableRecommendationsTags = sortByDescWrtSavingPercent.map(
      (re) => re.tag
    );

    const missingRecommendationsByTag =
      selectedVm?.recommended_action !== RECOMMENDATION_TYPE_OPTIMAL_V2
        ? Object.keys(RECOMMENDATION_TAGS).filter((tag) => {
            return !availableRecommendationsTags.includes(tag);
          })
        : [];

    const listOfAllRecommendationCostSaving = sortByDescWrtSavingPercent.map(
      (recom) => recom.saving?.num
    );
    const maxSavingsPossibleFromRecommendation = Math.max(
      ...listOfAllRecommendationCostSaving
    );

    return {
      rows: sortByDescWrtSavingPercent,
      maxSavingsPossibleFromRecommendation,
      missingRecommendationsByTag,
    };
  }, [selectedVm]);

  const getColumns = (cloudProvider) => {
    if (cloudProvider === CLOUD_PROVIDER_AWS) {
      return columns;
    } else if (cloudProvider === CLOUD_PROVIDER_OCI) {
      const tempCols = [
        ...columns.slice(0, 2),
        ...[
          { field: "ocpu", headerName: "oCPUs", width: 80, filterable: true },
        ],
        ...columns.slice(2, columns.length),
      ];
      return tempCols;
    } else {
      return columns;
    }
  };

  return (
    <Box gridColumn="span 5" style={{ height: "100%" }}>
      {selectedVm && selectedVm?.instance_name ? (
        <Card
          style={{
            border: "2px solid rgba(33, 150, 243, 0.3)",
            marginBottom: "20px",
          }}
        >
          <CardContent>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Box
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Tooltip title={selectedVm?.instance_name}>
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      style={{
                        maxWidth: "20ch",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {selectedVm?.instance_name}
                    </Typography>
                  </Tooltip>
                  <Typography variant="subtitle2">
                    {selectedVm?.machine_type}
                  </Typography>
                </Box>
                <Box style={{ display: "flex", gap: "20px" }}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1">Memory</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {selectedVm?.memory}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1">vCPU</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {selectedVm?.vcpu}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1">Architecture</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {selectedVm?.architecture}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" textAlign="center">
                        Avg. CPU Utilization
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {selectedVm?.avg_cpu_utilization}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" textAlign="center">
                        Max CPU Utilization
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {selectedVm?.max_cpu_utilization}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" textAlign="center">
                        Physical Processor
                      </Typography>
                    </Box>
                    <Box>
                      <Tooltip title={selectedVm?.physical_processor}>
                        <Typography
                          variant="subtitle2"
                          style={{
                            maxWidth: "20ch",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {selectedVm?.physical_processor}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography variant="h5" fontWeight="bold">
                  {selectedVm?.cost_per_month?.str}
                </Typography>
                <Typography variant="caption">Current Price</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ) : null}
      <Card style={{ height: selectedVm ? "calc(100% - 197px)" : "100%" }}>
        <CardContent style={{ height: "100%" }}>
          {rows.length === 0 ? (
            <EmptyStateView
              selectedVm={selectedVm}
              areAllVmsOptimal={areAllVmsOptimal}
            />
          ) : (
            <>
              <Box
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "flex-end",
                }}
              >
                <FormControl disabled fullWidth>
                  <InputLabel id="select-ocpus-label">oCPUs</InputLabel>
                  <Select
                    labelId="select-ocpus-label"
                    id="select-ocpus"
                    // value={selectedCloudProvider}
                    label="oCPUs"
                    // onChange={onCloudChanged}
                    // sx={FILTER_SELECT_STYLE}
                  >
                    {/* {CLOUD_PROVIDERS.map((cloudProvider) => (
                      <MenuItem key={cloudProvider} value={cloudProvider}>
                        {getCloudProviderName(cloudProvider)}
                      </MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
                <FormControl disabled fullWidth>
                  <InputLabel id="select-memory-label">Memory in GB</InputLabel>
                  <Select
                    labelId="select-memory-label"
                    id="select-memory"
                    // value={selectedCloudProvider}
                    label="Memory in GB"
                    // onChange={onCloudChanged}
                    // sx={FILTER_SELECT_STYLE}
                  >
                    {/* {CLOUD_PROVIDERS.map((cloudProvider) => (
                      <MenuItem key={cloudProvider} value={cloudProvider}>
                        {getCloudProviderName(cloudProvider)}
                      </MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
                <FormControl disabled fullWidth>
                  <InputLabel id="select-usage-type-label">
                    Usage Type
                  </InputLabel>
                  <Select
                    labelId="select-usage-type-label"
                    id="select-usage-type"
                    // value={selectedCloudProvider}
                    label="Usage Type"
                    // onChange={onCloudChanged}
                    // sx={FILTER_SELECT_STYLE}
                  >
                    {/* {CLOUD_PROVIDERS.map((cloudProvider) => (
                      <MenuItem key={cloudProvider} value={cloudProvider}>
                        {getCloudProviderName(cloudProvider)}
                      </MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
                <FormControl disabled fullWidth>
                  <InputLabel id="select-instance-storage-label">
                    Instance Storage
                  </InputLabel>
                  <Select
                    labelId="select-instance-storage-label"
                    id="select-instance-storage"
                    // value={selectedCloudProvider}
                    label="Instance Storage"
                    // onChange={onCloudChanged}
                    // sx={FILTER_SELECT_STYLE}
                  >
                    {/* {CLOUD_PROVIDERS.map((cloudProvider) => (
                      <MenuItem key={cloudProvider} value={cloudProvider}>
                        {getCloudProviderName(cloudProvider)}
                      </MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
                <FormControl disabled fullWidth>
                  <InputLabel id="select-networking-label">
                    Networking
                  </InputLabel>
                  <Select
                    labelId="select-networking-label"
                    id="select-networking"
                    // value={selectedCloudProvider}
                    label="Networking"
                    // onChange={onCloudChanged}
                    // sx={FILTER_SELECT_STYLE}
                  >
                    {/* {CLOUD_PROVIDERS.map((cloudProvider) => (
                      <MenuItem key={cloudProvider} value={cloudProvider}>
                        {getCloudProviderName(cloudProvider)}
                      </MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
                <Box textAlign="center">
                  <Typography variant="h5" fontWeight="bold" color="green">
                    ${maxSavingsPossibleFromRecommendation}/m
                  </Typography>
                  <Typography
                    variant="caption"
                    color="green"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Estimated Savings
                  </Typography>
                </Box>
                {/* <Box>
                  <UploadFileIcon style={{ color: "rgba(0, 0, 0, 0.56)" }} />
                </Box>
                <Box>
                  <SettingsIcon style={{ color: "rgba(0, 0, 0, 0.56)" }} />
                </Box> */}
              </Box>
              <Box
                sx={{
                  "& .header-row": {
                    backgroundColor: "rgba(33, 150, 243, 0.1)",
                  },
                  margin: "20px 0",
                }}
              >
                <DataGrid
                  rows={rows}
                  columns={getColumns(cloudProvider)}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "tag", sort: "desc" }],
                    },
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  autoHeight
                  hideFooter
                  density="comfortable"
                />
              </Box>
              {missingRecommendationsByTag.length > 0 ? (
                <Box
                  style={{
                    border: "1px solid rgba(33, 150, 243, 0.5)",
                    padding: "10px",
                    borderRadius: "4px",
                    margin: "20px 0",
                  }}
                >
                  Please note: No{" "}
                  <b>
                    {missingRecommendationsByTag
                      .map((tag) => RECOMMENDATION_TAGS[tag])
                      .join(", ")}
                  </b>{" "}
                  Recommendation available.
                </Box>
              ) : null}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CrossCloudOptimizeView;
