import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import {
  GradeintText,
  HeaderText,
  SimpleButton,
  TagLineText,
} from "./LandingPage";
import { debugColor } from "../../debugTools/debugColors";
import history from "../../utils/history";
import { ROUTE_SIGNUP } from "../../routers/routes";
import Calendly from "../../components/Calendly/Calendly";

export const ButtonStyle = {
  fontSize: {
    mobile: "12px",
    tablet: "12px",
    laptop: "14px",
    desktop: "14px",
  },
  width: {
    mobile: "140px", // this had to be overriden than design, coz as per design was text overflowing
    tablet: "146px",
    laptop: "172px",
    desktop: "172px",
  },
  height: {
    mobile: "32px",
    tablet: "34px",
    laptop: "40px",
    desktop: "40px",
  },
};

export default function HeroSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("mobile"));

  return (
    <Box
      sx={{
        width: "100%",
        background: {
          mobile: debugColor("red"),
          tablet: debugColor("green"),
          laptop: debugColor("blue"),
          desktop: debugColor("yellow"),
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        display="inline-flex"
        sx={{
          whiteSpace: {
            mobile: "wrap",
            tablet: "nowrap",
            laptop: "nowrap",
            desktop: "nowrap",
          },
          mb: {
            mobile: "24px",
            tablet: "16px",
            laptop: "12px",
            desktop: "24px",
          },
        }}
      >
        {isMobile ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GradeintText variant="h6">Plan</GradeintText>
              <HeaderText variant="h6">&nbsp;Your</HeaderText>
            </Box>
            <HeaderText
              variant="h6"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              Cloud Spend
            </HeaderText>
          </Box>
        ) : (
          <>
            <GradeintText variant="h6">Plan</GradeintText>
            <HeaderText variant="h6">&nbsp;Your Cloud Spend</HeaderText>
          </>
        )}
      </Box>
      <Box
        display="inline-flex"
        textAlign="center"
        whiteSpace="nowrap"
        sx={{
          mb: {
            mobile: "20px",
            tablet: "32px",
            laptop: "48px",
            desktop: "36px",
          },
        }}
      >
        <TagLineText variant="h4">Size Right, Pay Right</TagLineText>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            mobile: "column",
            tablet: "row",
            laptop: "row",
            desktop: "row",
          },
          gap: {
            mobile: "16px",
            tablet: "34px",
            laptop: "40px",
            desktop: "40px",
          },
          mb: {
            mobile: "48px",
            tablet: "56px",
            laptop: "72px",
            desktop: "48px",
          },
        }}
      >
        {/* we are not allowing to navigate to signup page
         on a mobile device */}
        {!isMobile && (
          <SimpleButton
            sx={{
              ...ButtonStyle,
              display: { mobile: "none", laptop: "block" },
            }}
            onClick={() => {
              history.push(ROUTE_SIGNUP);
            }}
          >
            SignUp For Free
          </SimpleButton>
        )}
        <Calendly />
      </Box>
      <Box
        sx={{
          textAlign: "center",
          width: {
            mobile: "auto", // WARN: this is deviating from design. It was introducing horizontal scroll
            tablet: "428px",
            laptop: "588px",
            desktop: "705px",
          },
        }}
      >
        <Typography variant="h4">
          Nudge Your Cloud Costs Down While Maintaining Peak Performance
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: "1ch",
            mt: "20px",
          }}
        >
          <Typography variant="h5">Cut Costs |</Typography>
          <Typography variant="h5">Maximize Efficiency |</Typography>
          <Typography variant="h5">Discover Hidden Savings</Typography>
        </Box>
      </Box>
    </Box>
  );
}
